import React, { useEffect,useState } from 'react';
import styles from './OverviewPage.module.scss';
import TotalLineChart from '../../../components/site/TotalSales/TotalSales';
import { useDispatch, useSelector } from 'react-redux';
import OverviewItem from '../../../components/site/OverviewItem/OverviewItem';
import { Helmet } from 'react-helmet';
import { userStatistics } from '../../../redux/actions/user/userStatistics';
import { userStatisticMonth } from '../../../redux/actions/user/userStatisticMonth';
import OverviewMonth from '../OverviewMonth/OverviewMonth';
import OverviewPartnerDashboard from '../../../components/site/OverviewPartnerDashboard/OverviewPartnerDashboard';
import { currencyFormat } from '../../../utils/currencyFormat';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import Steps from '../../../components/site/Steps/Steps';
import Video from '../../../components/site/Video/Video';


const OverviewPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userStatistics());
  }, []);
  
  const {
    userProfile: { data: userProfile = {}},
    userStatistics: { data: dataUserStatistics = {}, loading: loadingUserStatistics },
  } = useSelector((state) => state.user);

  const [extensionData, setExtensionData] = useState(null);
  const [telegramData, setTelegramData] = useState(null);
  const [totalEarningsSum, setTotalEarningsSum] = useState(0);
  const [totalRefSum, setTotalRefSum] = useState(0);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('https://extension.sellstat.kz/auth/profile/statistics/',{
          headers: {
            'Authorization': `Bearer ${token}`, 
        },
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
        const data = await response.json();

        setExtensionData(data.extension || {});
        setTelegramData(data.telegram || {});

        const sum = (data.extension.total_earnings || 0) + (data.telegram.total_earnings || 0);
        setTotalEarningsSum(sum);
        const ref_sum = (data.extension.referral_used_count || 0) + (data.telegram.referral_count || 0);
        setTotalRefSum(ref_sum);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);
  
    const videoJsOptions = {
    autoplay: false,
    controls: true,
    responsive: true,
    fluid: true,
    sources: [
      {
        src: 'img/video.mp4',
        type: 'video/mp4',
      },
    ],
  };
  
  const navigate = useNavigate();
  return (
    <>
      {userProfile?.partner && (
        <>
          <div className="crm demo6" style={{ marginBottom: '5px', marginTop: '10px' }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcrumb-main justify-content-center mb-20">
                    <h4 className="breadcrumb-title text-center">Общая статистика Telegram</h4>
                  </div>
                </div>
              </div>
              <div className="row" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <div className="col-12 col-sm-6 col-md-4 mb-10">
                  <div className="overview-content products-awards flex-fill pt-20 pb-20 text-center radius-xl center" style={{ position: 'relative', overflow: 'hidden',}}>
                    <div className="d-inline-flex flex-column align-items-center justify-content-center">
                      <div className="d-flex align-items-start flex-wrap">
                        <div>
                          <p className="mb-1 mb-0 color-gray" style={{ marginTop: 0 }}>
                            Количество оплат
                          </p>
                          <h1>
                            {telegramData?.referral_purchases != null
                              ? currencyFormat(telegramData.referral_purchases)
                              : 'Нет данных'}
                          </h1>
                        </div>
                      </div>
                    </div>
                    {(!dataUserStatistics || loadingUserStatistics) && <Skeleton count={1} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />}
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 mb-10">
                  <div className="overview-content products-awards flex-fill pt-20 pb-20 text-center radius-xl" style={{ position: 'relative', overflow: 'hidden',  }}>
                    <div className="d-inline-flex flex-column align-items-center justify-content-center">
                      <div className="d-flex align-items-start flex-wrap">
                        <div>
                          <p className="mb-1 mb-0 color-gray" style={{ marginTop: 0 }}>
                            Количество регистрации
                          </p>
                          <h1>
                            {telegramData?.referral_count != null
                              ? currencyFormat(telegramData.referral_count)
                              : 'Нет данных'}
                          </h1>
                        </div>
                      </div>
                    </div>
                    {(!dataUserStatistics || loadingUserStatistics) && <Skeleton count={1} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />}
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-4 mb-10">
                  <div className="overview-content products-awards flex-fill pt-20 pb-20 text-center radius-xl" style={{ position: 'relative', overflow: 'hidden', }}>
                    <div className="d-inline-flex flex-column align-items-center justify-content-center">
                      <div className="d-flex align-items-start flex-wrap">
                        <div>
                          <p className="mb-1 mb-0 color-gray" style={{ marginTop: 0 }}>
                            Итоговая комиссия
                          </p>
                          <h1>
                            {telegramData?.total_earnings != null
                              ? currencyFormat(telegramData.total_earnings)
                              : 'Нет данных'}
                          </h1>
                        </div>
                      </div>
                    </div>
                    {(!dataUserStatistics || loadingUserStatistics) && <Skeleton count={1} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          {/* Новый раздел "Статистика сайта" */}
          <div className="crm demo6" style={{ marginBottom: '4px' }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcrumb-main justify-content-center mb-20">
                    <h4 className="breadcrumb-title text-center">Статистика сайта</h4>
                  </div>
                </div>
              </div>
              <div className="row" style={{ rowGap: '4px' }}>
                <div className="col-12 col-sm-6 col-md-6 mb-10">
                  <div className="overview-content products-awards pt-20 pb-20 text-center radius-xl" style={{ position: 'relative', overflow: 'hidden' }}>
                    <div className="d-inline-flex flex-column align-items-center justify-content-center">
                      <div className="d-flex align-items-start flex-wrap">
                        <div>
                          <p className="mb-1 mb-0 color-gray" style={{ marginTop: 0 }}>
                            Количество использовании
                          </p>
                          <h1>
                            {extensionData?.referral_used_count != null
                              ? currencyFormat(extensionData.referral_used_count)
                              : 'Нет данных'}
                          </h1>
                        </div>
                      </div>
                    </div>
                    {(!dataUserStatistics || loadingUserStatistics) && <Skeleton count={1} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />}
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6 mb-10">
                  <div className="overview-content products-awards pt-20 pb-20 text-center radius-xl" style={{ position: 'relative', overflow: 'hidden' }}>
                    <div className="d-inline-flex flex-column align-items-center justify-content-center">
                      <div className="d-flex align-items-start flex-wrap">
                        <div>
                          <p className="mb-1 mb-0 color-gray" style={{ marginTop: 0 }}>
                            Итоговая комиссия
                          </p>
                          <h1>
                            {extensionData?.total_earnings != null
                              ? currencyFormat(extensionData.total_earnings)
                              : 'Нет данных'}
                          </h1>
                        </div>
                      </div>
                    </div>
                    {(!dataUserStatistics || loadingUserStatistics) && <Skeleton count={1} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
  
          {/* Новый раздел "Общая статистика" */}
          <div className="crm demo6" style={{ marginBottom: '35px' }}>
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12">
                  <div className="breadcrumb-main justify-content-center mb-20">
                    <h4 className="breadcrumb-title text-center">Общая статистика</h4>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <div className="col-12 col-sm-6 col-md-4 mb-10">
                  <div className="overview-content products-awards pt-15 pb-15 text-center radius-xl center" style={{ position: 'relative', overflow: 'hidden' }}>
                    <div className="d-inline-flex flex-column align-items-center justify-content-center">
                      <div className="d-flex align-items-start flex-wrap">
                        <div>
                          <p className="mb-1 color-gray" style={{ marginTop: 0 }}>
                            Условия
                          </p>
                          <h6>1-ая оплата — 30%</h6>
                          <h6 style={{ marginTop: '2px' }}>после — 20%</h6>
                        </div>
                      </div>
                    </div>
                    {(!dataUserStatistics || loadingUserStatistics) && <Skeleton count={1} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />}
                  </div>
                </div>
              </div>
              <div className="row" style={{ }}>
                <div className="col-12 col-sm-6 col-md-6 mb-10">
                  <div className="overview-content products-awards pt-20 pb-20 text-center radius-xl" style={{ position: 'relative', overflow: 'hidden' }}>
                    <div className="d-inline-flex flex-column align-items-center justify-content-center">
                      <div className="d-flex align-items-start flex-wrap">
                        <div>
                          <p className="mb-1 mb-0 color-gray" style={{ marginTop: '2px' }}>
                            Общее количество использовании
                          </p>
                          <h1>{currencyFormat(totalRefSum)}</h1>
                        </div>
                      </div>
                    </div>
                    {(!dataUserStatistics || loadingUserStatistics) && <Skeleton count={1} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />}
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-6">
                  <div className="overview-content products-awards pt-20 pb-20 text-center radius-xl" style={{ position: 'relative', overflow: 'hidden' }}>
                    <div className="d-inline-flex flex-column align-items-center justify-content-center">
                      <div className="d-flex align-items-start flex-wrap">
                        <div>
                          <p className="mb-1 mb-0 color-gray" style={{  }}>
                            Общее количество комиссии
                          </p>
                          <h1>{currencyFormat(totalEarningsSum)}</h1>
                        </div>
                      </div>
                    </div>
                    {(!dataUserStatistics || loadingUserStatistics) && <Skeleton count={1} style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <OverviewMonth />
          <OverviewPartnerDashboard />
        </>
      )}

<Helmet>
        <title>Главная</title>
      </Helmet>

      <div className="crm demo6 mb-25">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-main">
                <h4 className="text-capitalize breadcrumb-title">Пошаговая инструкция</h4>
              </div>
            </div>
            <Steps />
          </div>
        </div>
      <div className="crm demo6 mb-25">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="breadcrumb-main">
              <h4 className="text-capitalize breadcrumb-title">Видео инструкция</h4>
            </div>
            <div className="checkout wizard7 global-shadow border-0 px-sm-50 px-20 pt-sm-50 py-30 mb-30 bg-white radius-xl w-100">
              <Video options={videoJsOptions} />
            </div>
          </div>
        </div>
      </div>
      </div>
      </div>



    </>
  );
};
export default OverviewPage;

