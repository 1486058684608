import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import styles from './CartPage.module.scss';
import Tabs from '../../../components/site/common/Tabs/Tabs';
import { currencyFormat } from '../../../utils/currencyFormat';
import { tariffGetList } from '../../../redux/actions/tariff/tariffGetList';
import TariffCart from '../../../components/site/TariffCart/TariffCart';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { referralCheck } from '../../../redux/actions/referral/referralCheck';
import Loading from '../../../components/site/Loading/Loading';
import { resetReferralCheck } from '../../../redux/slices/referral.slice';
import { useSearchParams } from 'react-router-dom';
import { tariffPayment } from '../../../redux/actions/tariff/tariffPayment';
import { resetTariffPayment } from '../../../redux/slices/tariff.slice';
import { useMediaQuery } from '../../../utils/useMediaQuery';

export function percentage(price, discount) {
  var numVal1 = parseInt(price);
  var numVal2 = parseInt(discount) / 100;

  var totalValue = numVal1 - numVal1 * numVal2;
  return totalValue.toFixed(0);
}
export function percentageValue(price, discount) {
  var numVal1 = parseInt(price);
  var numVal2 = parseInt(discount) / 100;

  return numVal1 * numVal2;
}

const CartPage = () => {
  const form = useForm();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width: 576px)');
  const [searchParams, setSearchParams] = useSearchParams();

  const [activeTab, setActiveTab] = useState(searchParams.get('period') || '1_month');
  const [activeTariff, setActiveTariff] = useState(false);
  const [promo, setPromo] = useState('');
  const [isPromoActive, setIsPromoActive] = useState(false);
  const {
    tariffGetList: { data: tariffList },
    tariffPayment: { data: tariffPaymentData, loading: tariffPaymentLoading, error: tariffPaymentError },
  } = useSelector((state) => state.tariff);
  const {
    userProfile: { data: userProfile },
  } = useSelector((state) => state.user);
  const {
    referralCheck: { data: referralCheckData, loading: referralCheckLoading, error: referralCheckError },
  } = useSelector((state) => state.referral);

  useEffect(() => {
    if (tariffPaymentData?.payment_link && !tariffPaymentError) {
      window.location.href = tariffPaymentData?.payment_link;
    }
  }, [tariffPaymentData, tariffPaymentError]);
  useEffect(() => {
    return () => {
      dispatch(resetTariffPayment());
    };
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    if (tariffPaymentData && tariffPaymentError) {
      if (tariffPaymentData.error === 'Please link your Telegram account to your profile in order to make a payment.') {
        toast.error(
          <>
            <div
              style={{ display: 'inline-block', textDecoration: 'underline', textDecorationThickness: '1px', textUnderlineOffset: '2px', cursor: 'pointer' }}
              onClick={() => {
                navigate('/account/edit');
              }}>
              Войдите через телеграм
            </div>
            , чтобы купить тариф{' '}
          </>,
        );
      }
    }
  }, [tariffPaymentError, tariffPaymentData]);
  console.log(isPromoActive);
  useEffect(() => {
    if (referralCheckData?.valid) {
      toast.success('Промокод применен');
      setIsPromoActive({ code: referralCheckData?.referral_code, discount: referralCheckData?.discount_rate });
      dispatch(resetReferralCheck());
    }
  }, [referralCheckData]);
  useEffect(() => {
    if (referralCheckError) {
      toast.error('Промокод не найден');
      dispatch(resetReferralCheck());
    }
  }, [referralCheckError]);

  useEffect(() => {
    if (tariffList) {
      let tariffIndex = 0;
      if (searchParams.get('tariff')) {
        tariffIndex = tariffList.findIndex((tariff) => tariff?.id == searchParams.get('tariff'));
      }
      setActiveTariff({
        id: tariffList[tariffIndex].id,
        name: tariffList[tariffIndex].name,
        price: activeTab == '1_month' ? tariffList[tariffIndex].price : activeTab == '3_months' ? tariffList[tariffIndex].price * 3 : activeTab == '6_months' ? tariffList[tariffIndex].price * 6 : activeTab == '12_months' ? tariffList[tariffIndex].price * 12 : 0,
      });
    }
  }, [tariffList, activeTab]);
  const onPromoActivate = () => {
    if (promo) {
      dispatch(referralCheck(promo));
    }
    // if (promo === 'PROMO') {
    //   toast.success('Промокод применен');
    //   setIsPromoActive(true);
    // } else {
    //   toast.error('Промокод не найден');
    // }
  };
  const onPromoDelete = () => {
    toast.success('Промокод удален');
    setIsPromoActive(null);
    setPromo('');
  };
  const tabs = [
    {
      label: '1 месяц',
      value: '1_month',
      component: (
        <>
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit,minmax(250px,1fr)', gap: '20px 10px' }}>
            {tariffList?.map((item, index) => (
              <TariffCart period={activeTab} {...item} color={item?.color ?? 'primary'} isYear={false} active={activeTariff?.id == item?.id} setActive={setActiveTariff} />
            ))}
          </div>
        </>
      ),
    },
    {
      label: (
        <div className="d-flex align-items-center">
          {' '}
          <span style={{ marginRight: '0px' }}>3 месяц</span>
          <div class="dm-badge-list d-flex align-items-center" style={{ transform: 'translateY(1px)', marginLeft: '0px' }}>
            <span class="badge badge-round badge-danger badge-lg" style={{ lineHeight: '21px' }}>
              -- 10%
            </span>
          </div>
        </div>
      ),
      value: '3_months',
      component: (
        <>
          {' '}
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit,minmax(250px,1fr)', gap: '20px 10px' }}>
            {tariffList?.map((item, index) => (
              <TariffCart period={activeTab} {...item} color={item?.color ?? 'primary'} isYear={false} price={item?.price * 3} active={activeTariff?.id == item?.id} setActive={setActiveTariff} />
            ))}
          </div>
        </>
      ),
    },
    {
      label: (
        <div className="d-flex align-items-center">
          {' '}
          <span style={{ marginRight: '0px' }}>6 месяцев</span>
          <div class="dm-badge-list d-flex align-items-center" style={{ transform: 'translateY(1px)', marginLeft: '0px' }}>
            <span class="badge badge-round badge-danger badge-lg" style={{ lineHeight: '21px' }}>
              --20%
            </span>
          </div>
        </div>
      ),
      value: '6_months',
      component: (
        <>
          {' '}
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit,minmax(250px,1fr)', gap: '20px 10px' }}>
            {tariffList?.map((item, index) => (
              <TariffCart period={activeTab} {...item} color={item?.color ?? 'primary'} isYear={false} price={item?.price * 6} active={activeTariff?.id == item?.id} setActive={setActiveTariff} />
            ))}
          </div>
        </>
      ),
    },
    {
      label: (
        <div className="d-flex align-items-center">
          {' '}
          <span style={{ marginRight: '0px' }}>12 месяцев</span>
          <div class="dm-badge-list d-flex align-items-center" style={{ transform: 'translateY(1px)', marginLeft: '0px' }}>
            <span class="badge badge-round badge-danger badge-lg" style={{ lineHeight: '21px' }}>
              --30%
            </span>
          </div>
        </div>
      ),
      value: '12_months',
      component: (
        <>
          {' '}
          <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit,minmax(250px,1fr)', gap: '20px 10px' }}>
            {tariffList?.map((item, index) => (
              <TariffCart period={activeTab} {...item} color={item?.color ?? 'primary'} isYear={false} price={item?.price * 12} active={activeTariff?.id == item?.id} setActive={setActiveTariff} />
            ))}
          </div>
        </>
      ),
    },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(tariffGetList());
  }, []);

  return (
    <>
      <div class="dm-page-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="breadcrumb-main">
                <h4 class="text-capitalize breadcrumb-title">Оформление подписки</h4>
              </div>
            </div>
            <div class="col-12 col-lg-8">
              <div class="card card-default card-md mb-4">
                <div class="card-body p-20 p-md-30">
                  <Tabs isOutline list={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
                  {/* <div class="dm-nav-controller">
                    <div class="btn-group dm-button-group btn-group-normal nav" role="tablist">
                      <a href="#large" class="btn btn-sm btn-outline-light color-light nav-link active" id="size-large" data-bs-toggle="tab" role="tab" aria-selected="true">
                        Large
                      </a>
                      <a href="#default" class="btn btn-sm btn-outline-light color-light nav-link" id="size-default" data-bs-toggle="tab" role="tab" aria-selected="false">
                        Default
                      </a>
                      <a href="#small" class="btn btn-sm btn-outline-light color-light nav-link" id="size-small" data-bs-toggle="tab" role="tab" aria-selected="false">
                        Small
                      </a>
                    </div>
                  </div>
                  <div class="tab-content">
                    <div class="tab-pane fade active show" id="large" role="tabpanel" aria-labelledby="size-large">
                      <div class="tab-horizontal">
                        <div class="dm-tab tab-large">
                          <ul class="nav nav-tabs vertical-tabs" role="tablist">
                            <li class="nav-item">
                              <a class="nav-link active" id="size_large1-tab" data-bs-toggle="tab" href="#size_large1" role="tab" aria-selected="true">
                                Tab 1
                              </a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" id="size_large2-tab" data-bs-toggle="tab" href="#size_large2" role="tab" aria-selected="false">
                                Tab 2
                              </a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" id="size_large3-tab" data-bs-toggle="tab" href="#size_large3" role="tab" aria-selected="false">
                                Tab 3
                              </a>
                            </li>
                          </ul>
                          <div class="tab-content">
                            <div class="tab-pane fade show active" id="size_large1" role="tabpanel" aria-labelledby="size_large1-tab">
                              <p>Content of Tab Pane 1</p>
                            </div>
                            <div class="tab-pane fade" id="size_large2" role="tabpanel" aria-labelledby="size_large2-tab">
                              <p>Content of Tab Pane 2</p>
                            </div>
                            <div class="tab-pane fade" id="size_large3" role="tabpanel" aria-labelledby="size_large3-tab">
                              <p>Content of Tab Pane 3</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="default" role="tabpanel" aria-labelledby="size-default">
                      <div class="tab-horizontal">
                        <div class="dm-tab tab-default">
                          <ul class="nav nav-tabs vertical-tabs" role="tablist">
                            <li class="nav-item">
                              <a class="nav-link active" id="size_default1-tab" data-bs-toggle="tab" href="#size_default1" role="tab" aria-selected="true">
                                Tab 1
                              </a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" id="size_default2-tab" data-bs-toggle="tab" href="#size_default2" role="tab" aria-selected="false">
                                Tab 2
                              </a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" id="size_default3-tab" data-bs-toggle="tab" href="#size_default3" role="tab" aria-selected="false">
                                Tab 3
                              </a>
                            </li>
                          </ul>
                          <div class="tab-content">
                            <div class="tab-pane fade show active" id="size_default1" role="tabpanel" aria-labelledby="size_default1-tab">
                              <p>Content of Tab Pane 1</p>
                            </div>
                            <div class="tab-pane fade" id="size_default2" role="tabpanel" aria-labelledby="size_default2-tab">
                              <p>Content of Tab Pane 2</p>
                            </div>
                            <div class="tab-pane fade" id="size_default3" role="tabpanel" aria-labelledby="size_default3-tab">
                              <p>Content of Tab Pane 3</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane fade" id="small" role="tabpanel" aria-labelledby="size-small">
                      <div class="tab-horizontal">
                        <div class="dm-tab tab-small">
                          <ul class="nav nav-tabs vertical-tabs" role="tablist">
                            <li class="nav-item">
                              <a class="nav-link active" id="size_small1-tab" data-bs-toggle="tab" href="#size_small1" role="tab" aria-selected="true">
                                Tab 1
                              </a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" id="size_small2-tab" data-bs-toggle="tab" href="#size_small2" role="tab" aria-selected="false">
                                Tab 2
                              </a>
                            </li>
                            <li class="nav-item">
                              <a class="nav-link" id="size_small3-tab" data-bs-toggle="tab" href="#size_small3" role="tab" aria-selected="false">
                                Tab 3
                              </a>
                            </li>
                          </ul>
                          <div class="tab-content">
                            <div class="tab-pane fade show active" id="size_small1" role="tabpanel" aria-labelledby="size_small1-tab">
                              <p>Content of Tab Pane 1</p>
                            </div>
                            <div class="tab-pane fade" id="size_small2" role="tabpanel" aria-labelledby="size_small2-tab">
                              <p>Content of Tab Pane 2</p>
                            </div>
                            <div class="tab-pane fade" id="size_small3" role="tabpanel" aria-labelledby="size_small3-tab">
                              <p>Content of Tab Pane 3</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>{' '}
            <div class="col-12 col-lg-4">
              <div class=" order-summery" style={{ position: 'sticky', top: '84px' }}>
                <div class="card-body px-sm-25 px-20 pt-20">
                  <div className="pb-10 mb-10 " style={{ borderBottom: '1px solid var(--border-color)' }}>
                    <h4 className="mb-20 card-bordered">Ваша подписка</h4>
                    <div class="total">
                      <div class="subtotalTotal">
                        {activeTariff?.name}
                        <span>{currencyFormat(percentage(activeTariff?.price, activeTab == '3_months' ? 10 : activeTab == '6_months' ? 20 : activeTab == '12_months' ? 30 : 0))} ₸</span>
                      </div>
                      {isPromoActive && (
                        <div class="subtotalTotal">
                          Промокод
                          <span>--{currencyFormat(percentageValue(activeTariff?.price, isPromoActive?.discount))} ₸</span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div class="promo-code">
                    {' '}
                    <form>
                      <label for="exampleInputEmail1">Промокод</label>
                      <div class="d-flex align-items-center" style={{ position: 'relative' }}>
                        {isPromoActive && (
                          <span class="percent" style={{ position: 'absolute', top: '7px', left: '12px', width: '20px' }}>
                            <img src="/img/svg/coupon.svg" alt="svg" class="svg" />
                          </span>
                        )}

                        <input
                          autoComplete="off"
                          id="exampleInputEmail1"
                          type="text"
                          class="form-control"
                          value={promo}
                          style={{ ...(isPromoActive && { paddingLeft: '39px', fontWeight: '500', color: 'var(--color-success)' }) }}
                          onChange={(e) => {
                            if (!isPromoActive) {
                              setPromo(e.target.value);
                            }
                          }}
                        />
                        {isPromoActive ? (
                          <a href="#" class="btn link-danger" onClick={onPromoDelete} style={{ border: '1px solid var(--color-danger)', color: 'var(--color-danger)' }}>
                            Удалить
                          </a>
                        ) : (
                          <a href="#" class="btn" onClick={onPromoActivate}>
                            Применить
                          </a>
                        )}
                      </div>
                    </form>
                  </div>
                  <div class="total-money d-flex justify-content-between mt-50 align-items-end">
                    <h6>Итого:</h6>
                    <h3 style={{ color: 'var(--color-dark)' }}>
                      {isPromoActive
                        ? currencyFormat(percentage(activeTariff?.price, (activeTab == '3_months' ? 10 : activeTab == '6_months' ? 20 : activeTab == '12_months' ? 30 : 0) + isPromoActive?.discount))
                        : currencyFormat(percentage(activeTariff?.price, activeTab == '3_months' ? 10 : activeTab == '6_months' ? 20 : activeTab == '12_months' ? 30 : 0))}{' '}
                      ₸
                    </h3>
                  </div>
                  <a
                    onClick={() => {
                      dispatch(tariffPayment({ tariff_id: activeTariff.id, payment_period: activeTab, new_subscription: userProfile?.tariff_info?.id == activeTariff.id ? 'false' : 'true', ...(isPromoActive && { referral_code: isPromoActive.code }) }));
                    }}
                    href="#"
                    class="checkout btn-success content-center w-100 btn-lg mt-20">
                    {' '}
                    Оплатить{' '}
                    {isPromoActive
                      ? currencyFormat(percentage(activeTariff?.price, (activeTab == '3_months' ? 10 : activeTab == '6_months' ? 20 : activeTab == '12_months' ? 30 : 0) + isPromoActive?.discount))
                      : currencyFormat(percentage(activeTariff?.price, activeTab == '3_months' ? 10 : activeTab == '6_months' ? 20 : activeTab == '12_months' ? 30 : 0))}{' '}
                    ₸
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {(referralCheckLoading || tariffPaymentLoading) && <Loading />}
      </div>
    </>
  );
};

export default CartPage;
