import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import Video from '../Video/Video';
const Steps = () => {
  const steps = [
    {
      name: (
        <>
          Установить наше
          <br /> расширение
        </>
      ),
      text: (
        <>
          Вам необходимо перейти по <a href="https://chromewebstore.google.com/detail/sellstat-%D0%B2%D0%B0%D1%88-%D0%BF%D0%BE%D0%BC%D0%BE%D1%89%D0%BD%D0%B8%D0%BA-%D0%B2-%D0%B0/hffbedinjbkibpagebmimleihdilkiae?hl=ru">ссылке</a> и вы попадете в магазин расширений Google. Далее вы устанавливаете
          расширение SellStat.
        </>
      ),
    },
    {
      name: <>Вход в расширение</>,
      text: 'В правом верхнем углу вам нужно нажать на три точки. Вы откроете меню настроек Google. Выберите в этом пункте "Расширения". Далее "Управление расширениями". Нажмите на скачанное расширение. У вас откроется окно авторизации. Введите туда данные от кабинета SellStat. Поздравляем  вы сделали успешный вход.',
    },

    {
      name: <>Настройка</>,
      text: `Перейдите в личный кабинет продавца в kaspi.kz. Слева снизу нажмите 'Пользователи', затем 'Добавить пользователя'. Введите нужные данные и проставьте все галочки для корректной работы. На указанную почту придет сообщение от kaspi.kz где будет ваш новый пароль.`,
    },
    {
      name: (
        <>
          Подключение <br /> Kaspi кабинета
        </>
      ),
      text: `На сайте profile.sellstat.kz перейдите во вкладку настройки. Далее укажите почту и пароль от пользователя, которого мы создали в кабинете kaspi.kz. Укажите ID вашего магазина и нажмите кнопку "Сохранить".`,
    },
    {
      name: <>Тариф</>,
      text: `Супер, вы настроили все настройки, осталось только приобрести тариф и пользоваться полным функционалом нашего сервиса SellStat.`,
    },
  ];
  const [activeStep, setActiveStep] = useState(0);

  return (
    <>
      <div className=" checkout wizard7 global-shadow border-0 px-sm-50 px-20 pt-sm-50 py-30 mb-30 bg-white radius-xl w-100">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-12">
            <div className="checkout-progress-indicator content-center">
              <div className="checkout-progress justify-content-center">
                {steps?.map((step, stepIndex) => (
                  <>
                    {stepIndex !== 0 && (
                      <div className="current">
                        <img src={`img/svg/${stepIndex == activeStep ? 'checkoutin.svg' : 'checkout.svg'}`} alt="img" className="svg" />
                      </div>
                    )}

                    <div className={`step ${stepIndex == activeStep && 'current'}`} id={2}>
                      <span>{stepIndex + 1}</span>
                      <span>{step?.name}</span>
                    </div>
                  </>
                ))}
                {/* <div className="step" id={1}>
                  <span>1</span>
                  <span>
                    Установить наше
                    <br /> расширение
                  </span>
                </div>
                <div className="current">
                  <img src="img/svg/checkoutin.svg" alt="img" className="svg" />
                </div>
                <div className="step current" id={2}>
                  <span>2</span>
                  <span>Вход в расширение</span>
                </div>
                <div className="current">
                  <img src="img/svg/checkout.svg" alt="img" className="svg" />
                </div>
                <div className="step" id={3}>
                  <span>3</span>
                  <span>Настройка</span>
                </div>
                <div className="current">
                  <img src="img/svg/checkout.svg" alt="img" className="svg" />
                </div>
                <div className="step" id={4}>
                  <span>4</span>
                  <span>Подключение Kaspi кабинета</span>
                </div>{' '}
                <div className="current">
                  <img src="img/svg/checkout.svg" alt="img" className="svg" />
                </div>
                <div className="step" id={3}>
                  <span>5</span>
                  <span>Тариф</span>
                </div> */}
              </div>
            </div>
            {/* ends: .checkout-progress-indicator */}
            <div className="row justify-content-center">
              <div className="col-xl-7 col-lg-8 col-sm-10 checkout-shipping-form pt-20">
                <div class="step-text"> {steps[activeStep]?.text}</div>
                <div class="button-group d-flex pt-50 justify-content-between flex-wrap">
                  {activeStep > 0 ? (
                    <button
                      onClick={() => {
                        setActiveStep(activeStep - 1);
                      }}
                      class="btn btn-light btn-default btn-squared fw-400 text-capitalize m-1">
                      <i class="las la-arrow-left me-10"></i>Назад
                    </button>
                  ) : (
                    <div></div>
                  )}
                  {activeStep < steps?.length - 1 && (
                    <button
                      onClick={() => {
                        setActiveStep(activeStep + 1);
                      }}
                      class="btn text-white btn-primary btn-default btn-squared  m-1 ">
                      Следующий шаг<i class="ms-10 me-0 las la-arrow-right"></i>
                    </button>
                  )}
                </div>
              </div>
              {/* ends: .col */}
            </div>
          </div>
          {/* ends: col */}
        </div>
      </div>
    </>
  );
};

export default Steps;
