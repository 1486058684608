import { createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from 'axios';

export const initStateGetChat = {
  getChat: { data: null, loading: false, error: null },
};

export const getChat = createAsyncThunk('tariff/getChat', async (data, { rejectWithValue, fulfillWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });
  return await axios
    .get(`${process.env.REACT_APP_SERVER_API}/ai-chat/${data}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        // Accept: '*/*',
        // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        // Connection: 'keep-alive',
      },
    })
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((res) => {
      return rejectWithValue(res.response.data);
    });
});

export const reducerGetChat = {
  [getChat.pending]: (state) => {
    state.getChat.loading = true;
  },
  [getChat.fulfilled]: (state, action) => {
    state.getChat.loading = false;
    state.getChat.data = action.payload;
    state.getChat.error = null;
  },
  [getChat.rejected]: (state, action) => {
    state.getChat.loading = false;
    state.getChat.data = action?.payload;
    state.getChat.error = true;
  },
};
