import React, { useEffect, useRef, useState } from 'react';
import styles from './Chat.module.scss';
import ChatInput from '../../../components/site/ChatInput/ChatInput';
import ChatMessage from '../../../components/site/ChatMessage/ChatMessage';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { getChat } from '../../../redux/actions/chat/getChat';
import { useParams } from 'react-router';
import Loading from '../../../components/site/Loading/Loading';
import { sendMessageChat } from '../../../redux/actions/chat/sendMessageChat';
import { resetSendMessage } from '../../../redux/slices/chat.slice';
const Chat = ({ conversationId, chatList, setChatList, loading, loadingMessage }) => {
  const form = useForm();
  const {
    userAuth: { data: userData },
  } = useSelector((state) => state.user);
  const {
    sendMessageChat: { data: sendMessageData, loading: sendMessageLoading },
  } = useSelector((state) => state.chat);
  const dispatch = useDispatch();

  useEffect(() => {
    if (sendMessageData) {
      setChatList([...chatList, { self: false, text: sendMessageData?.response }]);
      dispatch(resetSendMessage());
    }
  }, [sendMessageData]);

  const onSubmit = (data) => {
    setChatList([...chatList, { self: true, text: data.message, name: userData?.email?.substring(0, 1) }]);
    dispatch(
      sendMessageChat({
        message: data.message,
        conversation_id: conversationId,
      }),
    );
    form.setValue('message', '');
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => {
    scrollToBottom();
  }, [chatList, sendMessageLoading]);

  return (
    <>
      <div className={clsx(styles.wrap)}>
        {loading ? (
          <Loading isTransparent style={{ position: 'absolute' }} />
        ) : (
          <>
            {' '}
            <div className={clsx(styles.messageList)}>
              {!chatList?.length ? <div className={clsx(styles.emptyMessages)}>Сообщений нет</div> : chatList?.map((item) => <ChatMessage {...item} />)} {(sendMessageLoading || loadingMessage) && <ChatMessage self={false} loading />}
              <div ref={messagesEndRef}></div>
            </div>
            <ChatInput form={form} name="message" onClick={form.handleSubmit(onSubmit)} disabled={sendMessageLoading || loadingMessage} />
          </>
        )}
      </div>
    </>
  );
};

export default Chat;
