import React from 'react';
import './StatInlineList.scss';
import StatInlineItem from '../StatInlineItem/StatInlineItem';
import clsx from 'clsx';
const StatInlineList = ({ list, big, ...props }) => {
  return (
    <>
      <div {...props}>
        {' '}
        <div className="row ">
          <div className={clsx('bg-white pt-25 ps-15  col-12 d-flex justify-content-between', !big && 'pb-40')} style={{ borderRadius: '10px', paddingRight: '15%', ...(big && { paddingBottom: '70px' }) }}>
            {list?.map((item) => (
              <StatInlineItem {...item} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default StatInlineList;
