import React from 'react';
import styles from './Loading.module.scss';
const Loading = ({ isTransparent = false, isWhite = false, isDark = false, style = {} }) => {
  return (
    <>
      {' '}
      <div className="overlay" style={{ ...(isWhite && { background: '#fff' }), ...(isDark && { background: '#131314' }), ...(isTransparent && { background: 'transparent', ...style }) }}>
        <div class="loader-overlay">
          <div class="dm-spin-dots spin-lg">
            <span class="spin-dot badge-dot dot-primary"></span>
            <span class="spin-dot badge-dot dot-primary"></span>
            <span class="spin-dot badge-dot dot-primary"></span>
            <span class="spin-dot badge-dot dot-primary"></span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Loading;
