import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import Input from '../Input/Input';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { toast } from 'react-toastify';
import { setPhoneNumber, setStepRegistration } from '../../../redux/slices/app.slice';
import { userConfirmCode } from '../../../redux/actions/user/userConfirmCode';
import { resetUserConfirmCode, resetUserResendCode } from '../../../redux/slices/user.slice';
import { userResendCode } from '../../../redux/actions/user/userResendCode';
const StepInputSms = () => {
  const form = useForm({ mode: 'onBlur', defaultValues: { sms: '' } });
  const { phoneNumber } = useSelector((state) => state.app);
  const {
    userResendCode: { data: resendCodeData, loading: resendCodeLoading },
    userConfirmCode: { data: confirmCodeData, loading: confirmCodeLoading, error: confirmCodeError },
  } = useSelector((state) => state.user);
  const [timeLeft, setTimeLeft] = useState(59);
  const [allowedSend, setAllowedSend] = useState(false);
  const onSubmit = (data) => {
    dispatch(userConfirmCode({ sms_code: data.sms?.toString(), phone_number: phoneNumber?.formatted }));
  };

  useEffect(() => {
    if (!allowedSend) {
      const interval = setInterval(() => {
        setTimeLeft((prev) => {
          if (prev === 0) {
            return prev;
          } else {
            let decriment = prev - 1;
            if (decriment === 0) {
              setAllowedSend(true);
              clearInterval(interval);
            }
            return decriment;
          }
        });
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [allowedSend]);

  const onResendCode = () => {
    dispatch(userResendCode(phoneNumber?.formatted));
  };

  const [showErrorAnimationCode, setShowErrorAnimationCode] = useState(false);
  const [showErrorMessageCode, setShowErrorMessageCode] = useState(false);
  useEffect(() => {
    if (confirmCodeError) {
      setShowErrorAnimationCode(true);
      setShowErrorMessageCode(true);
      setTimeout(() => {
        form.setValue('sms', '');
        setShowErrorAnimationCode(false);
      }, 300);
    }
  }, [confirmCodeError]);

  useEffect(() => {
    if (confirmCodeData) {
      toast.success('Код подтвержден');
      dispatch(setStepRegistration(2));
      dispatch(resetUserResendCode());
      dispatch(resetUserConfirmCode());
    }
  }, [confirmCodeData]);

  useEffect(() => {
    if (resendCodeData) {
      toast.success('Новый код подтверждения отправлен');
      setTimeLeft(59);
      setAllowedSend(false);
      form.setValue('sms', '');
      dispatch(resetUserResendCode());
    }
  }, [resendCodeData]);

  useEffect(() => {
    form.register('sms', {
      validate: { value: (value, formValues) => value?.toString()?.length === 6 || 'Не полный формат кода' },
    });
  }, []);
  React.useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      if (name == 'sms' && value?.sms?.toString()?.length === 6) {
        form.clearErrors('sms');
      }
      if (name == 'sms' && value?.sms) {
        setShowErrorMessageCode(false);
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);
  const dispatch = useDispatch();
  return (
    <>
      <Helmet>
        <title>Код из смс</title>
      </Helmet>
      <div class="card border-0">
        <div class="card-header" style={{ position: 'relative' }}>
          <div class="edit-profile__title " style={{ textTransform: 'none' }}>
            <div
              onClick={() => {
                dispatch(setStepRegistration(0));
                dispatch(setPhoneNumber(null));
              }}
              class="color-dark"
              style={{ color: 'var(--color-dark)', fontSize: '14px', opacity: '0.5', position: 'absolute', transform: 'translateY(-50%)', left: '20px', top: '50%', userSelect: 'none', cursor: 'pointer' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor" style={{ marginBottom: '2.5px' }}>
                <path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path>
              </svg>
              Назад
            </div>
            <h4>Код из смс</h4>
          </div>
        </div>
        <div class="card-body">
          <div class="edit-profile__body">
            <div class="edit-profile__body">
              <div style={{ color: 'var(--color-dark)', marginBottom: '10px', textAlign: 'center' }}>
                На номер <b style={{ textDecoration: 'underline', textUnderlineOffset: '5px' }}>{phoneNumber?.formatted}</b> отправлено <br /> смс с кодом подтверждения
              </div>
              <div className={showErrorAnimationCode ? 'shake' : ''} style={{ maxWidth: '195px', margin: '0 auto', marginBottom: '30px' }}>
                <Input
                  styleWrap={{ marginBottom: '5px' }}
                  styleError={{ marginTop: '2px', marginBottom: '3px', textAlign: 'center' }}
                  form={form}
                  name={'sms'}
                  type={'sms'}
                  label={''}
                  classInput={showErrorMessageCode ? 'sms-invalide' : ''}
                  rules={{
                    required: { message: 'Обязательное поле', value: true },
                  }}
                />
                <div className="" style={{ fontSize: '13px', textAlign: 'center' }}>
                  {' '}
                  <div
                    onClick={() => {
                      if (allowedSend) {
                        onResendCode();
                      }
                    }}
                    className={`text-${allowedSend ? 'primary' : 'body'} extend-btn`}
                    style={{ display: 'inline-block', textDecoration: 'underline', textUnderlineOffset: '4px', marginTop: '0px', cursor: 'pointer', userSelect: 'none' }}>
                    Отправить код повторно
                  </div>
                  {!allowedSend && <>&nbsp;можно через 00:{timeLeft < 10 ? `0${timeLeft}` : timeLeft}</>}
                </div>
              </div>

              <div class="admin__button-group button-group d-flex pt-1 justify-content-md-start justify-content-center">
                <Button className="text-capitalize lh-normal px-50 signIn-createBtn " style={{ margin: '0 auto' }} onClick={form.handleSubmit(onSubmit)}>
                  Подтвердить
                </Button>
              </div>
              {showErrorMessageCode && (
                <div style={{ textAlign: 'center', marginTop: '10px' }} className="text-danger">
                  Неверный код
                </div>
              )}
              <p class="mt-20 d-flex align-items-center justify-content-center mb-0">
                Уже есть аккаунт?&nbsp;
                <Link
                  to="/login"
                  class="color-primary"
                  onClick={() => {
                    dispatch(setPhoneNumber(null));
                    dispatch(setStepRegistration(0));
                    dispatch(resetUserResendCode());
                    dispatch(resetUserConfirmCode());
                  }}>
                  Войти
                </Link>
              </p>
              {/* {signUpError?.non_field_errors?.[0] && <p className="error pt-1 d-flex justify-content-center">{signUpError?.non_field_errors?.[0]}</p>} */}
            </div>
          </div>
        </div>

        {(confirmCodeLoading || resendCodeLoading) && <Loading />}
      </div>
    </>
  );
};

export default StepInputSms;
