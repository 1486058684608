import React from 'react';
import styles from './Table.scss';
import clsx from 'clsx';
const Table = ({ list = [], headers = [], className = '' }) => {
  return (
    <>
      <div className={clsx('row', className)}>
        <div className="col-12 mb-30" data-select2-id={21}>
          <div className="support-ticket-system support-ticket-system--search" data-select2-id={20}>
            <div className="support-form datatable-support-form d-flex justify-content-xxl-between justify-content-start align-items-center flex-wrap">
              <div
                style={{
                  fontSize: '14px',
                  fontWeight: 400,
                  color: '#404040',
                  marginRight: '18px',
                  textTransform: 'capitalize',
                }}>
                Поиск
              </div>
              <div className="support-form__search">
                <div className="support-order-search">
                  {' '}
                  <form style={{ minWidth: '190px', padding: '10px 12px ' }} action="/" className="support-order-search__form">
                    <input className="form-control border-0 box-shadow-none" type="search" placeholder="Введите название" aria-label="Search" />
                  </form>
                </div>
              </div>{' '}
            </div>
            <div className="userDatatable userDatatable--ticket userDatatable--ticket--2 mt-1">
              <div className="table-responsive">
                <table className="table mb-0 table-borderless">
                  <thead>
                    <tr className="userDatatable-header">
                      {headers?.map((item) => (
                        <th>
                          <span className="userDatatable-title">{item}</span>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {list?.map((item) => (
                      <tr style={{ ...(item?.bgColor && { background: `${item?.bgColor}` }) }}>
                        {item?.row?.map((row) => (
                          <td>
                            <div style={{ ...(item?.color && { color: `${item?.color}` }) }} className={clsx('userDatatable-content--subject')}>
                              {row}
                            </div>
                          </td>
                        ))}
                      </tr>

                      // <tr>
                      //   <td>#01</td>
                      //   <td>
                      //     <div className="d-flex">
                      //       <div className="userDatatable-inline-title">
                      //         <a href="#" className="text-dark fw-500">
                      //           <h6>Kellie Marquot</h6>
                      //         </a>
                      //       </div>
                      //     </div>
                      //   </td>
                      //   <td>
                      //     <div className="userDatatable-content--subject">United Street</div>
                      //   </td>
                      //   <td>
                      //     <div className="userDatatable-content--subject">Business Development</div>
                      //   </td>
                      //   <td>
                      //     <div className="userDatatable-content--priority">Web Developer</div>
                      //   </td>
                      //   <td>
                      //     <div className="userDatatable-content--priority">January 20, 2020</div>
                      //   </td>
                      //   <td>
                      //     <div className="userDatatable-content d-inline-block">
                      //       <span className="bg-opacity-success  color-success userDatatable-content-status active">active</span>
                      //     </div>
                      //   </td>
                      //   <td>
                      //     <ul className="orderDatatable_actions mb-0 d-flex flex-wrap">
                      //       <li>
                      //         <a href="#" className="view">
                      //           <i className="uil uil-setting" />
                      //         </a>
                      //       </li>
                      //       <li>
                      //         <a href="#" className="edit">
                      //           <i className="uil uil-edit" />
                      //         </a>
                      //       </li>
                      //       <li>
                      //         <a href="#" className="remove">
                      //           <i className="uil uil-trash-alt" />
                      //         </a>
                      //       </li>
                      //     </ul>
                      //   </td>
                      // </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-end pt-25">
                <nav className="dm-page ">
                  <ul className="dm-pagination d-flex">
                    <li className="dm-pagination__item">
                      <a href="#" className="dm-pagination__link pagination-control">
                        <span className="la la-angle-left" />
                      </a>
                      <a href="#" className="dm-pagination__link">
                        <span className="page-number">1</span>
                      </a>
                      <a href="#" className="dm-pagination__link active">
                        <span className="page-number">2</span>
                      </a>
                      <a href="#" className="dm-pagination__link">
                        <span className="page-number">3</span>
                      </a>
                      <a href="#" className="dm-pagination__link pagination-control">
                        <span className="page-number">...</span>
                      </a>
                      <a href="#" className="dm-pagination__link">
                        <span className="page-number">12</span>
                      </a>
                      <a href="#" className="dm-pagination__link pagination-control">
                        <span className="la la-angle-right" />
                      </a>
                      <a href="#" className="dm-pagination__option"></a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
