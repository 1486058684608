import React, { useEffect, useState } from 'react';
import styles from './ChatLayout.module.scss';
import ChatSidebar from '../ChatSidebar/ChatSidebar';
import clsx from 'clsx';
import { menuData } from '../HomeLayout/HomeLayout';
import { Link, useNavigate } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { useDispatch, useSelector } from 'react-redux';
import { userAuth } from '../../../redux/actions/user/userAuth';
import { userProfile } from '../../../redux/actions/user/userProfile';
import { resetUserAuth, resetUserProfile } from '../../../redux/slices/user.slice';
import { getChatList } from '../../../redux/actions/chat/getChatList';
import Loading from '../Loading/Loading';
import { useMediaQuery } from '../../../utils/useMediaQuery';
const ChatLayout = ({ children }) => {
  const [show, setShow] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const {
    userAuth: { data: userData, loading: userLoading, error: userError },
  } = useSelector((state) => state.user);

  const dipsatch = useDispatch();
  useEffect(() => {
    dipsatch(userAuth());
    dipsatch(userProfile());
    dipsatch(getChatList());
  }, []);
  const navigate = useNavigate();
  useEffect(() => {
    if (userError) {
      localStorage.removeItem('token');
      dipsatch(resetUserAuth());
      dipsatch(resetUserProfile());
      navigate('/login');
    }
  }, [userError]);

  const {
    createChat: { data: createChatData, loading: createChatLoading },
    getChatList: { data: chatListData },
    createdChatId,
  } = useSelector((state) => state.chat);
  const isMobile = useMediaQuery('(max-width: 992px)');
  return (
    <>
      {userData ? (
        <div className={clsx(styles.wrap)}>
          <ChatSidebar show={show} setShow={setShow} />
          <div className={clsx(styles.content)}>
            <div className={clsx(styles.header)}>
              <div className={clsx(styles.left)}>
                {isMobile && (
                  <div
                    className={clsx(styles.menuBtn)}
                    onClick={() => {
                      setShow(true);
                    }}></div>
                )}
                <img src="/img/logo-dark.svg" alt="logo" className={clsx(styles.logo)} />
                {isMobile && (
                  <div
                    onClick={() => {
                      if (!createChatLoading) {
                        navigate('/chat/new');
                      }
                    }}
                    className={clsx(styles.newChat, createChatLoading && styles.newChatDisabled)}></div>
                )}
              </div>
              <div className={clsx(styles.right)}>
                <OutsideClickHandler
                  onOutsideClick={() => {
                    setShowMenu(false);
                  }}>
                  <div className={clsx(styles.accountWrap)}>
                    {' '}
                    <div
                      onClick={() => {
                        setShowMenu(!showMenu);
                      }}
                      className={clsx(styles.account)}>
                      <div className={clsx(styles.avatar, showMenu && styles.avatarShow)}>{userData?.email?.substring(0, 1)}</div>
                      <div className={clsx(styles.email, showMenu && styles.emailShow)}>
                        <span>{userData?.email}</span>
                      </div>
                    </div>{' '}
                    <div className={clsx(styles.menu, showMenu && styles.menuShow)}>
                      {isMobile && <div className={clsx(styles.menuEmail, showMenu && styles.menuEmailShow)}>{userData?.email}</div>}
                      <div className={clsx(styles.menuExit)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                          <path
                            fill="currentColor"
                            d="M4 12a1 1 0 0 0 1 1h7.59l-2.3 2.29a1 1 0 0 0 0 1.42a1 1 0 0 0 1.42 0l4-4a1 1 0 0 0 .21-.33a1 1 0 0 0 0-.76a1 1 0 0 0-.21-.33l-4-4a1 1 0 1 0-1.42 1.42l2.3 2.29H5a1 1 0 0 0-1 1M17 2H7a3 3 0 0 0-3 3v3a1 1 0 0 0 2 0V5a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1v-3a1 1 0 0 0-2 0v3a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V5a3 3 0 0 0-3-3"></path>
                        </svg>
                        Выход
                      </div>
                      <div className={clsx(styles.menuList)}>
                        {menuData?.map((item) => (
                          <Link to={item?.link} className={clsx(styles.item)}>
                            <span className={clsx(`nav-icon uil ${item?.icon}`, styles.itemIcon)}></span>

                            <div className={clsx(styles.itemName)}>{item?.label}</div>
                          </Link>
                        ))}
                      </div>
                    </div>
                  </div>{' '}
                </OutsideClickHandler>
              </div>
            </div>
            <div className={clsx(styles.contentContainer)}> {children}</div>
            <div className={clsx(styles.footer)}>
              <a href="/privacy.pdf" target="_blank" className={clsx(styles.policy)}>
                Ваша конфиденциальность и приложения SellStat
              </a>
            </div>
          </div>
        </div>
      ) : (
        <Loading isDark />
      )}
    </>
  );
};

export default ChatLayout;
