import { createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from 'axios';

export const initStateUserSaveNewPassword = {
  userSaveNewPassword: { data: null, loading: false, error: null },
};

export const userSaveNewPassword = createAsyncThunk('user/userSaveNewPassword', async (data, { rejectWithValue, fulfillWithValue }) => {
  return await axios
    .post(`${process.env.REACT_APP_SERVER_API}/auth/password/reset/confirm/`, data, {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        Connection: 'keep-alive',
      },
    })
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((res) => {
      console.log(res);
      return rejectWithValue(res.response.data);
    });
});

export const reducerUserSaveNewPassword = {
  [userSaveNewPassword.pending]: (state) => {
    state.userSaveNewPassword.loading = true;
    state.userSaveNewPassword.error = null;
  },
  [userSaveNewPassword.fulfilled]: (state, action) => {
    state.userSaveNewPassword.loading = false;
    state.userSaveNewPassword.data = action.payload;
    state.userSaveNewPassword.error = null;
  },
  [userSaveNewPassword.rejected]: (state, action) => {
    state.userSaveNewPassword.loading = false;
    state.userSaveNewPassword.error = action.payload;
  },
};
