import { configureStore } from '@reduxjs/toolkit';
import { appReducer } from './slices/app.slice';
import { userReducer } from './slices/user.slice';
import { tariffReducer } from './slices/tariff.slice';
import { dampingReducer } from './slices/damping.slice';
import { chatReducer } from './slices/chat.slice';
import { referralReducer } from './slices/referral.slice';

export const store = configureStore({
  reducer: {
    app: appReducer,
    user: userReducer,
    tariff: tariffReducer,
    damping: dampingReducer,
    chat: chatReducer,
    referral: referralReducer,
  },
});
