import React, { useState } from 'react';
import styles from './Tabs.scss';
import clsx from 'clsx';
import { useMediaQuery } from '../../../../utils/useMediaQuery';
const Tabs = ({ style, isFilled = false, list, activeTab, setActiveTab, isOutline = false }) => {
  return (
    <>
      <div class={isOutline || isFilled ? 'dm-nav-controller' : 'dm-tab tab-horizontal'}>
        <ul class={isOutline || isFilled ? 'btn-group dm-button-group btn-group-normal nav' : 'nav nav-tabs vertical-tabs'} role="tablist" style={{ ...((isOutline || isFilled) && { display: 'grid', gridTemplateColumns: `repeat(${list?.length},1fr)`, ...style }) }}>
          {list?.map((item) => (
            <li
              style={{ ...((isOutline || isFilled) && { width: '100%' }) }}
              key={item.value}
              class={clsx(isFilled ? 'text-primary btn btn-white btn-default btn-square nav-link' : isOutline ? 'btn btn-sm btn-outline-light color-light nav-link' : 'nav-item', activeTab === item.value && isOutline ? 'active' : activeTab === item.value && isFilled ? 'active-filled' : false)}
              onClick={() => {
                setActiveTab(item?.value);
              }}>
              <div class={clsx(isOutline || isFilled ? '' : 'nav-link', activeTab === item.value && 'active')} href="#tab-v-1" role="tab" style={{ whiteSpace: 'nowrap' }}>
                {item?.label}
              </div>
            </li>
          ))}
        </ul>
        <div class="tab-content mt-35">{list?.find((item) => item.value == activeTab)?.component}</div>
      </div>
    </>
  );
};

export default Tabs;
