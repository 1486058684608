// import React, { useEffect, useRef, useState } from 'react';
// import styles from './ChatSinglePage.module.scss';
// import ChatInput from '../../../components/site/ChatInput/ChatInput';
// import ChatMessage from '../../../components/site/ChatMessage/ChatMessage';
// import { useDispatch, useSelector } from 'react-redux';
// import clsx from 'clsx';
// import { useForm } from 'react-hook-form';
// import { getChat } from '../../../redux/actions/chat/getChat';
// import { useParams } from 'react-router';
// import Loading from '../../../components/site/Loading/Loading';
// import { sendMessageChat } from '../../../redux/actions/chat/sendMessageChat';
// import { resetSendMessage } from '../../../redux/slices/chat.slice';
// const ChatSinglePage = () => {
//   const form = useForm();
//   const [chatList, setChatList] = useState([]);
//   const {
//     userAuth: { data: userData, loading: userLoading, error: userError },
//   } = useSelector((state) => state.user);
//   const {
//     getChat: { data: chatData, loading: chatLoading },
//     sendMessageChat: { data: sendMessageData, loading: sendMessageLoading },
//   } = useSelector((state) => state.chat);
//   const dispatch = useDispatch();
//   const { id } = useParams();
//   useEffect(() => {
//     if (sendMessageData) {
//       setChatList([...chatList, { self: false, text: sendMessageData?.response }]);
//       dispatch(resetSendMessage());
//     }
//   }, [sendMessageData]);

//   useEffect(() => {
//     if (chatData) {
//       setChatList(chatData?.messages?.map((item) => ({ self: !item?.is_bot, text: item?.message, name: userData?.email?.substring(0, 1) })));
//     }
//   }, [chatData]);

//   useEffect(() => {
//     if (id) {
//       dispatch(getChat(id));
//     }
//   }, [id]);
//   const onSubmit = (data) => {
//     setChatList([...chatList, { self: true, text: data.message, name: userData?.email?.substring(0, 1) }]);
//     dispatch(
//       sendMessageChat({
//         message: data.message,
//         conversation_id: id,
//       }),
//     );
//     form.setValue('message', '');
//   };

//   const messagesEndRef = useRef(null);

//   const scrollToBottom = () => {
//     messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
//   };
//   useEffect(() => {
//     scrollToBottom();
//   }, [chatList, sendMessageLoading]);

//   return (
//     <>
//       <div className={clsx(styles.wrap)}>
//         {chatLoading ? (
//           <Loading isTransparent style={{ position: 'absolute' }} />
//         ) : (
//           <>
//             {' '}
//             <div className={clsx(styles.messageList)}>
//               {!chatList?.length ? <div className={clsx(styles.emptyMessages)}>Сообщений нет</div> : chatList?.map((item) => <ChatMessage {...item} />)} {sendMessageLoading && <ChatMessage self={false} loading />}
//               <div ref={messagesEndRef}></div>
//             </div>
//             <ChatInput form={form} name="message" onClick={form.handleSubmit(onSubmit)} disabled={sendMessageLoading} />
//           </>
//         )}
//       </div>
//     </>
//   );
// };

// export default ChatSinglePage;

import React, { useEffect, useRef, useState } from 'react';
import styles from './ChatSinglePage.module.scss';
import ChatInput from '../../../components/site/ChatInput/ChatInput';
import ChatMessage from '../../../components/site/ChatMessage/ChatMessage';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { getChat } from '../../../redux/actions/chat/getChat';
import { useParams } from 'react-router';
import Loading from '../../../components/site/Loading/Loading';
import { sendMessageChat } from '../../../redux/actions/chat/sendMessageChat';
import { resetSendMessage } from '../../../redux/slices/chat.slice';
import Chat from '../../../components/site/Chat/Chat';
const ChatSinglePage = () => {
  const [chatList, setChatList] = useState([]);
  const {
    userAuth: { data: userData, loading: userLoading, error: userError },
  } = useSelector((state) => state.user);
  const {
    getChat: { data: chatData, loading: chatLoading },
  } = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (chatData) {
      setChatList(chatData?.messages?.map((item) => ({ self: !item?.is_bot, text: item?.message, name: userData?.email?.substring(0, 1) })));
    }
  }, [chatData]);

  useEffect(() => {
    if (id) {
      dispatch(getChat(id));
    }
  }, [id]);

  return (
    <>
      <Chat loading={chatLoading} conversationId={id} chatList={chatList} setChatList={setChatList} loadingMessage={false} />
    </>
  );
};

export default ChatSinglePage;
