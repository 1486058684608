import React, { useEffect } from 'react';
import styles from './AccountPage.module.scss';
import AccountLayout from '../../../components/site/AccountLayout/AccountLayout';
import { useForm } from 'react-hook-form';
import Input from '../../../components/site/Input/Input';
import Button from '../../../components/site/Button/Button';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Loading from '../../../components/site/Loading/Loading';
import { userUpdate } from '../../../redux/actions/user/userUpdate';
import { resetUserUpdate } from '../../../redux/slices/user.slice';
import { userAuth } from '../../../redux/actions/user/userAuth';
import { Helmet } from 'react-helmet';
import TelegramLoginButton from 'telegram-login-button';
import { userTelegram } from '../../../redux/actions/user/userTelegram';
import moment from 'moment';
import { tariffPayment } from '../../../redux/actions/tariff/tariffPayment';
import { resetTariffPayment } from '../../../redux/slices/tariff.slice';
import { userProfile } from '../../../redux/actions/user/userProfile';
const AccountPage = () => {
  const defaultValues = {
    email: '',
    fio: '',
  };

  const form = useForm({ defaultValues });
  const {
    tariffGetList: { data: tariffList },
    tariffPayment: { data: tariffPaymentData, loading: tariffPaymentLoading },
  } = useSelector((state) => state.tariff);

  const {
    userAuth: { data: userData },
    userProfile: { data: userProfileTelegram },
    userTelegram: { data: userTelegramData },
    userUpdate: { data: userUpdateData, loading: userUpdateLoading, error: userUpdateError },
  } = useSelector((state) => state.user);
  useEffect(() => {
    if (userData) {
      form.setValue('email', userData?.email);
      form.setValue('fio', `${userData?.first_name ?? ''}${userData?.first_name && userData?.last_name ? ' ' : ''}${userData?.last_name ?? ''}`);
    }
  }, [userData]);
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    const fio = data?.fio?.split(' ');
    dispatch(
      userUpdate({
        first_name: fio[0],
        last_name: fio[1] ?? '',
      }),
    );
  };
  const navigate = useNavigate();
  useEffect(() => {
    if (userUpdateData) {
      dispatch(resetUserUpdate());
      toast.success('Данные профиля обновлены');
      dispatch(userAuth());
    }
  }, [userUpdateData]);
  useEffect(() => {
    if (userTelegramData) {
      toast.success('Телеграм привязан');
      dispatch(userProfile());
    }
  }, [userTelegramData]);
  // useEffect(() => {
  //   if (tariffPaymentData) {
  //     window.location.href = tariffPaymentData?.payment_link;
  //   }
  // }, [tariffPaymentData]);
  return (
    <>
      {' '}
      <Helmet>
        <title>Профиль</title>
      </Helmet>
      <div class="row justify-content-center">
        <div class="col-xxl-6">
          <div class="edit-profile__body mx-xl-20">
            {' '}
            <Input form={form} name={'fio'} label={'Имя Фамилия'} classWrap={'mb-20'} />
            <Input disabled form={form} name={'email'} label={'Почта'} classWrap={'mb-20'} />{' '}
            {/* <div class="d-flex justify-content-between mt-1 align-items-center flex-wrap">
              <div class="text-capitalize py-10">
                {userProfile?.tariff_info ? (
                  <>
                    {' '}
                    <h6>Тариф «{userProfile?.tariff_info?.name}»</h6>{' '}
                    <div class=" d-flex align-items-center color-light fw-400 mb-10" style={{ marginTop: '5px', fontSize: '14px' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="currentColor" style={{ marginRight: '4px' }}>
                        <path d="M15.09814,12.63379,13,11.42285V7a1,1,0,0,0-2,0v5a.99985.99985,0,0,0,.5.86621l2.59814,1.5a1.00016,1.00016,0,1,0,1-1.73242ZM12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Z"></path>
                      </svg>{' '}
                      Истекает {moment(userProfile?.end_date).format('DD.MM.YYYY')}
                      <div
                        className="text-primary text-decoration-underline extend-link"
                        style={{ userSelect: 'none', cursor: 'pointer', textUnderlineOffset: '4px', marginLeft: '10px' }}
                        onClick={() => {
                          dispatch(tariffPayment({ tariff_id: userProfile?.tariff_info?.id, payment_period: 'month', new_subscription: 'false' }));
                        }}>
                        Продлить
                      </div>
                    </div>
                  </>
                ) : (
                  <h6 class="text-danger" style={{ textTransform: 'none' }}>
                    Тариф не указан <i class="uil uil-exclamation-circle"></i>
                  </h6>
                )}

                <span class="fs-13 color-light fw-400 " style={{ textTransform: 'none' }}>
                  Чтобы просмотреть все тарифы нажмите на кнопку "Посмотреть"
                </span>
              </div>
              <div class="my-sm-0 my-10 py-10 " style={{ alignSelf: 'start' }}>
                <Button
                  onClick={() => {
                    navigate('/pricing');
                  }}>
                  Посмотреть
                </Button>
              </div>
            </div> */}
            <div class="button-group d-flex pt-0 mb-15 justify-content-start" style={{ width: 'min-content', whiteSpace: 'nowrap' }}>
              <Button onClick={form.handleSubmit(onSubmit)} className="btn-squared me-15 text-capitalize btn-success">
                Сохранить
              </Button>
            </div>
          </div>
          <h6 class="mb-15"> Войти через телеграм</h6>
          {userProfileTelegram?.telegram_user_id ? (
            <div style={{ display: 'flex', alignItems: 'center', height: '47.2px', fontWeight: '500', color: '#01b81a' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#01b81a" style={{ marginRight: '5px', marginBottom: '1px' }}>
                <path d="M14.72,8.79l-4.29,4.3L8.78,11.44a1,1,0,1,0-1.41,1.41l2.35,2.36a1,1,0,0,0,.71.29,1,1,0,0,0,.7-.29l5-5a1,1,0,0,0,0-1.42A1,1,0,0,0,14.72,8.79ZM12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z"></path>
              </svg>
              Телеграм привязан
            </div>
          ) : (
            <TelegramLoginButton botName="sellstat_bot" dataOnauth={(user) => dispatch(userTelegram(user))} className="telegram-btn" />
          )}

          {userProfileTelegram?.tariff_info && (
            <div class="mt-20 mb-10" style={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', columnGap: '20px', rowGap: '15px' }}>
              <Button
                className="btn-danger"
                onClick={() => {
                  navigate('/pricing');
                }}>
                <span class="nav-icon uil uil-bill"></span> Сбросить тариф
              </Button>
              <Button
                className="btn-danger"
                style={{}}
                onClick={() => {
                  navigate('/account/reset-password');
                }}>
                <div className="" style={{ WebkitMaskImage: 'url(/img/svg/key.svg)', width: '16px', height: '16px', backgroundColor: '#fff', WebkitMaskSize: 'contain', marginRight: '8px' }}></div>
                Сброс пароля
              </Button>
            </div>
          )}
        </div>
        {(userUpdateLoading || tariffPaymentLoading) && <Loading />}
      </div>
    </>
  );
};

export default AccountPage;
