import React from 'react';
import './Tab3.scss';
import StatCardList from '../../site/common/StatCardList/StatCardList';
import StatInlineList from '../../site/common/StatInlineList/StatInlineList';
import Table from '../../site/common/Table/Table';
import Button from '../../site/Button/Button';
const Tab3 = () => {
  const list1 = [
    {
      label: 'Ожидаем',
      value: 'на 1 000 000 ₸',
      caption: '50 ед - 50 %',
      bigMargin: true,
    },
    {
      label: 'Поступило',
      value: 'на 1 000 000 ₸',
      caption: '50 ед - 50 %',
      bigMargin: true,
    },
  ];
  const headers = ['Дата', 'Артикул продавца', 'Наименование', 'Заказано', 'Себестоимость', 'Сумма', 'Точка учета', 'Дата поступления', 'Пришло', 'Дата прихода', 'Поставщик'];

  const listTable = [
    { row: ['27.02.2024', 'Артикул 9100196-57654645', 'Logitech серый', '1 шт.', '4 012 ₸', '4 012 ₸', 'Алматы', '27.02.2024', '50 шт.', '27.02.2024', 'Китай'] },
    { row: ['27.02.2024', 'Артикул 9100196-57654645', 'Logitech серый', '1 шт.', '4 012 ₸', '4 012 ₸', 'Алматы', '27.02.2024', '50 шт.', '27.02.2024', 'Китай'] },
  ];
  return (
    <>
      <StatInlineList list={list1} className="container-fluid" big />
      <div className="d-flex align-items-center mt-20">
        <Button className="me-10">Добавить запись</Button>
        <Button className="me-10" type="outline">
          Добавить запись
        </Button>
        <Button className="me-10" type="outline">
          Удалить запись
        </Button>{' '}
        <Button color="danger">Закрыть приход</Button>
      </div>
      <Table className="mt-20" headers={headers} list={listTable} />
    </>
  );
};

export default Tab3;
