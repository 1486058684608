import React, { useEffect } from 'react';
import styles from './TableOpportunityRow.scss';
import clsx from 'clsx';
import TableOpportunityRowInput from '../TableOpportunityRowInput/TableOpportunityRowInput';
import Input from '../../Input/Input';
import { useFieldArray } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { updateDamping } from '../../../../redux/actions/damping/updateDamping';

const TableOpportunityRow = ({ index, data: { isSelected, title, image, productId }, form }) => {
  const { fields, update, replace } = useFieldArray({
    control: form.control,
    name: `products[${index}].cityList`,
  });

  const isSelectedAllWatch = form.watch(`products[${index}].isSelectAll`);
  React.useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      if (name == 'products') {
        replace(value.products[index].cityList);
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);
  const nameInputsRow = [
    { name: 'min_price', isPrice: true },
    { name: 'max_price', isPrice: true },
    { name: 'step_price', isPrice: true },
    { name: 'quantity', isPrice: false },
  ];
  return (
    <>
      <tr>
        <td style={{ padding: '16px 16px 8px 16px' }}>
          <div className="d-flex align-items-center">
            {' '}
            <img src={image} alt="" className="" style={{ borderRadius: '8px', marginRight: '14px', width: '43px', minWidth: '43px', height: '43px', objectFit: 'contain' }} />
            <div className="d-flex flex-column" style={{ maxWidth: '300px' }}>
              <div className="" style={{ whiteSpace: 'normal' }}>
                {title}
              </div>
              <div className="fs-12 fw-400" style={{ color: '#8C90A4' }}>
                Код товара: {productId}
              </div>
              {/* <div className="fs-12 fw-400" style={{ color: '#8C90A4' }}>
                {isBotActive ? <div className="text-success"> Бот включен</div> : <div className="text-danger"> Бот выключен </div>}
              </div> */}
            </div>
          </div>
          <div style={{ marginTop: '24px', paddingLeft: '25px' }}>
            <Input type="checkbox" classWrap="checkbox-row" name={`products[${index}].isSelectAll`} form={form} label={isSelectedAllWatch ? 'Убрать все' : 'Выбрать все'} />
          </div>
        </td>
      </tr>{' '}
      {fields?.map((item, indexCity) => (
        <tr style={{ height: '53px', paddingBottom: '8px' }} key={item?.title}>
          <td style={{ padding: '0 0 0 16px' }}>
            <div style={{ background: item?.damping_info_id ? 'rgba(var(--color-success-rgba),0.1)' : 'rgba(var(--color-danger-rgba),0.1)', padding: '16px 16px 16px 25px', display: 'flex', alignItems: 'center' }}>
              {' '}
              <Input type="checkbox" classWrap="checkbox-row" name={`products[${index}].cityList[${indexCity}].isSelected`} form={form} />
              {item?.name}
            </div>
          </td>

          <td style={{ padding: '0' }}>
            <div style={{ display: 'flex', alignItems: 'center', background: item?.damping_info_id ? 'rgba(var(--color-success-rgba),0.1)' : 'rgba(var(--color-danger-rgba),0.1)', justifyContent: 'center', height: '53px' }}>
              <TableOpportunityRowInput notEdit value={item?.price} isPrice />
            </div>
          </td>
          {nameInputsRow?.map((nameInput) => (
            <td style={{ padding: '0' }}>
              <div style={{ display: 'flex', alignItems: 'center', background: item?.damping_info_id ? 'rgba(var(--color-success-rgba),0.1)' : 'rgba(var(--color-danger-rgba),0.1)', justifyContent: 'center', height: '53px' }}>
                <TableOpportunityRowInput selectedName={`products[${index}].cityList[${indexCity}].isSelected`} isPrice={nameInput.isPrice} indexProduct={index} form={form} update={update} index={indexCity} name={nameInput.name} item={item} value={item?.[nameInput.name]} />
              </div>
            </td>
          ))}

          {/* <td style={{ padding: '0' }}>
            <div style={{ display: 'flex', alignItems: 'center', background: item?.damping_info_id ? 'rgba(var(--color-success-rgba),0.1)' : 'rgba(var(--color-danger-rgba),0.1)', justifyContent: 'center', height: '53px' }}>
              <TableOpportunityRowInput isShowError={form.watch(`products[${index}].cityList[${indexCity}].isSelected`)} form={form} selected update={update} index={indexCity} name={'max_price'} item={item} value={item?.max_price} />
            </div>
          </td>
          <td style={{ padding: '0' }}>
            <div style={{ display: 'flex', alignItems: 'center', background: item?.damping_info_id ? 'rgba(var(--color-success-rgba),0.1)' : 'rgba(var(--color-danger-rgba),0.1)', justifyContent: 'center', height: '53px' }}>
              <TableOpportunityRowInput isShowError={form.watch(`products[${index}].cityList[${indexCity}].isSelected`)} update={update} index={indexCity} name={'step_price'} item={item} value={item?.step_price} />
            </div>
          </td> */}
        </tr>
      ))}
    </>
  );
};

export default TableOpportunityRow;
