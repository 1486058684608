import React, { useEffect, useState } from 'react';
import styles from './TableOpportunityRowInput.scss';
import clsx from 'clsx';
import OutsideClickHandler from 'react-outside-click-handler';
import { NumericFormat } from 'react-number-format';
import { currencyFormat } from '../../../../utils/currencyFormat';
import { useDispatch } from 'react-redux';
import { updateDamping } from '../../../../redux/actions/damping/updateDamping';
import { toast } from 'react-toastify';
const TableOpportunityRowInput = ({ isPrice, indexProduct, index, name, item, update, notEdit, value, form, selectedName }) => {
  const isSelectedRow = !!form?.watch(selectedName);
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState(value);
  useEffect(() => {}, []);
  const onSave = (val) => {
    // const {productId}
    const product = form.getValues(`products[${indexProduct}]`);
    let updateData = { ...item, [name]: val, isSelected: isSelectedRow };
    update(index, updateData);
    if (item?.damping_info_id) {
      toast.success('Данные обновлены', { autoClose: 2000 });
      dispatch(
        updateDamping([
          {
            product_id: product?.productId,
            damping_info: [{ min_price: updateData.min_price, max_price: updateData.max_price, step_price: updateData.step_price, city_name: item?.name }],
            quantity: [
              {
                city_name: item?.name,
                quantity: updateData?.quantity,
              },
            ],
          },
        ]),
      );
    }
  };
  return (
    <>
      {isEdit ? (
        <OutsideClickHandler
          onOutsideClick={() => {
            if (!notEdit) {
              setIsEdit(false);
              setInputValue(undefined);
            }
          }}>
          {' '}
          <div className="" style={{ position: 'relative' }}>
            <NumericFormat
              thousandSeparator={' '}
              onValueChange={(e) => {
                setInputValue(e.floatValue);
              }}
              style={{
                ...(typeof inputValue !== 'number' && {
                  borderColor: 'var(--color-danger)',
                }),
                boxShadow: 'none',
                textAlign: 'center',
                width: '75px',
                fontSize: '14px',
                fontWeight: '500',
              }}
              autoFocus
              class="form-control form-control-sm "
              value={inputValue}
            />
            {/* <input type="text" value={value} /> */}
            <div className="" style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', position: 'absolute', top: '100%', left: '50%', transform: 'translateX(-50%)' }}>
              {' '}
              <i
                onClick={() => {
                  if (typeof inputValue == 'number') {
                    setIsEdit(false);
                    onSave(inputValue);

                    setInputValue(undefined);
                  }
                }}
                class="uil uil-check color-success"
                style={{ fontSize: '24px', cursor: 'pointer' }}></i>
              <i
                onClick={() => {
                  setIsEdit(false);
                  setInputValue(undefined);
                }}
                class="uil uil-multiply color-danger"
                style={{ fontSize: '19px', cursor: 'pointer' }}></i>
            </div>
          </div>
        </OutsideClickHandler>
      ) : (
        <div
          onClick={() => {
            if (!notEdit) {
              setIsEdit(true);
              setInputValue(value);
            }
          }}
          className=""
          style={{ display: 'flex', alignItems: 'center', height: '30px', justifyContent: 'center' }}>
          <div className="" style={{ opacity: typeof value == 'number' || isSelectedRow ? 1 : 0.6, ...(isSelectedRow && typeof value != 'number' && { color: 'var(--color-danger)' }) }}>
            {' '}
            {typeof value == 'number' ? `${currencyFormat(value)}${isPrice ? ' ₸' : ''}` : '???'}
          </div>
        </div>
      )}
    </>
  );
};

export default TableOpportunityRowInput;
