import React, { useEffect } from 'react';
import styles from './InputNewPasswordPage.module.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { userResetPassword } from '../../../redux/actions/user/userResetPassword';
import { resetUserResetPassword } from '../../../redux/slices/user.slice';
import Input from '../../../components/site/Input/Input';
import Button from '../../../components/site/Button/Button';
import Loading from '../../../components/site/Loading/Loading';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import { userSaveNewPassword } from '../../../redux/actions/user/userSaveNewPassword';
const InputNewPasswordPage = () => {
  const defaultValues = {};
  const form = useForm({ defaultValues });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uid, token } = useParams();

  const {
    userSaveNewPassword: { data: userSaveNewPasswordData, error: userSaveNewPasswordError, loading: userSaveNewPasswordLoading },
  } = useSelector((state) => state.user);
  const onSubmit = ({ new_password2, new_password1 }) => {
    dispatch(
      userSaveNewPassword({
        new_password1,
        new_password2,
        uid,
        token,
      }),
    );
  };
  useEffect(() => {
    if (userSaveNewPasswordData) {
      toast.success('Пароль успешно сохранен');
      navigate('/login');
    }
  }, [userSaveNewPasswordData]);
  useEffect(() => {
    if (userSaveNewPasswordError) {
      toast.error('Произошла непредвиденная ошибка');
    }
  }, [userSaveNewPasswordError]);
  useEffect(() => {
    if (userSaveNewPasswordError) {
      Object.keys(userSaveNewPasswordError).forEach(function (key, index) {
        if (key !== 'non_field_errors') {
          form.setError(key, { type: 'custom', message: userSaveNewPasswordError[key]?.[0] });
        }
      });
    }
  }, [userSaveNewPasswordError]);
  return (
    <>
      <Helmet>
        <title>Новый пароль</title>
      </Helmet>
      <div class="card border-0">
        <div class="card-header">
          <div class="edit-profile__title">
            <h4>Новый пароль</h4>
          </div>
        </div>
        <div class="card-body">
          <div class="edit-profile__body">
            <p>Введите ваш новый пароль</p>
            <Input
              form={form}
              name={'new_password1'}
              label={'Новый пароль'}
              type="password"
              classWrap={'mb-20'}
              rules={{
                required: { message: 'Обязательное поле', value: true },
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/i,
                  message: 'Пароль должен содержать минимум: \n - Одну заглавную букву \n- Одну маленькую букву \n- Одну цифру \n- Длинну более 8 символов ',
                },
              }}
            />
            <Input
              form={form}
              name={'new_password2'}
              label={'Повторный новый пароль'}
              classWrap={'mb-20'}
              type="password"
              rules={{
                required: { message: 'Обязательное поле', value: true },
                validate: (val) => {
                  if (form.watch('new_password1') != val) {
                    return 'Пароли не совпадают';
                  }
                },
              }}
            />{' '}
            <div class="button-group d-flex pt-10 mb-30 justify-content-start" style={{ width: 'min-content', whiteSpace: 'nowrap' }}>
              <Button className="btn-squared me-15 btn-success" onClick={form.handleSubmit(onSubmit)}>
                Сохранить пароль
              </Button>
            </div>
          </div>
        </div>
        {userSaveNewPasswordLoading && <Loading />}
      </div>
    </>
  );
};

export default InputNewPasswordPage;
