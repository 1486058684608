import React from 'react';
import './Tab1.scss';
import StatCardList from '../../site/common/StatCardList/StatCardList';
import StatInlineList from '../../site/common/StatInlineList/StatInlineList';
import APITokenShop from '../APITokenShop/APITokenShop';
const Tab1 = () => {
  const list1 = [
    {
      value: '0 ₸',
      label: 'Всего продаж',
      icon: (
        <svg width="30" height="18" viewBox="0 0 30 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15 3.78516C12.5975 3.78516 10.65 6.05712 10.65 8.86016C10.65 11.6627 12.5975 13.9352 15 13.9352C17.4016 13.9352 19.35 11.6636 19.35 8.86016C19.35 6.05712 17.4025 3.78516 15 3.78516ZM16.8125 11.3977C16.8125 11.5979 16.6503 11.7602 16.45 11.7602H13.55C13.3497 11.7602 13.1875 11.5979 13.1875 11.3977V10.6727C13.1875 10.4724 13.3497 10.3102 13.55 10.3102H14.275V7.79803L14.2537 7.81208C14.2141 7.8385 14.1697 7.85686 14.123 7.8661C14.0763 7.87535 14.0283 7.8753 13.9816 7.86595C13.9349 7.85661 13.8905 7.83816 13.851 7.81165C13.8115 7.78515 13.7776 7.75111 13.7512 7.71148L13.3488 7.10837C13.3224 7.06878 13.304 7.02437 13.2948 6.97768C13.2855 6.93099 13.2856 6.88293 13.2949 6.83626C13.3043 6.78959 13.3227 6.74522 13.3492 6.70568C13.3757 6.66615 13.4098 6.63223 13.4494 6.60586L14.144 6.14277C14.3226 6.02368 14.5325 5.96014 14.7472 5.96016H15.3625C15.5628 5.96016 15.725 6.12237 15.725 6.32266V10.3102H16.45C16.6503 10.3102 16.8125 10.4724 16.8125 10.6727V11.3977ZM28.05 0.160156H1.95C1.14933 0.160156 0.5 0.809484 0.5 1.61016V16.1102C0.5 16.9108 1.14933 17.5602 1.95 17.5602H28.05C28.8507 17.5602 29.5 16.9108 29.5 16.1102V1.61016C29.5 0.809484 28.8507 0.160156 28.05 0.160156ZM27.325 12.4852C25.7232 12.4852 24.425 13.7834 24.425 15.3852H5.575C5.575 13.7834 4.2768 12.4852 2.675 12.4852V5.23516C4.2768 5.23516 5.575 3.93695 5.575 2.33516H24.425C24.425 3.93695 25.7232 5.23516 27.325 5.23516V12.4852Z"
            // fill="var(--color-primary)"
          />
        </svg>
      ),
      statValue: 15.65,
      statLabel: 'С прошлого месяца',
    },
    {
      color: 'warning',

      value: '0 ₸',
      label: 'Средний чек ',
      icon: (
        <svg width="27" height="21" viewBox="0 0 27 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.43359 1.36133H25.2314V17.2266C25.2314 18.687 24.0476 19.8708 22.5872 19.8708H4.0778C2.61744 19.8708 1.43359 18.687 1.43359 17.2266V1.36133Z" stroke="#FA8B0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M6.72266 13.2598H10.689" stroke="#FA8B0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M6.72266 7.9707H19.9437" stroke="#FA8B0C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
      statValue: 15.65,
      statLabel: 'С прошлого месяца',
    },
    {
      color: 'success',

      value: '0 ₸',
      label: 'Выручка',
      icon: (
        <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.3125 19.6095C6.3125 22.0902 7.29797 24.4694 9.05212 26.2235C10.8063 27.9777 13.1854 28.9631 15.6661 28.9631C18.1469 28.9631 20.526 27.9777 22.2802 26.2235C24.0343 24.4694 25.0198 22.0902 25.0198 19.6095C25.0198 17.1288 24.0343 14.7496 22.2802 12.9955C20.526 11.2413 18.1469 10.2559 15.6661 10.2559C13.1854 10.2559 10.8063 11.2413 9.05212 12.9955C7.29797 14.7496 6.3125 17.1288 6.3125 19.6095Z"
            fill="#BDE9C5"
          />
          <path d="M15.6649 25.0355L10.0527 18.3633H21.2771L15.6649 25.0355Z" fill="#01B81A" />
          <path d="M13.793 4.02344H17.5344V20.2364H13.793V4.02344Z" fill="#01B81A" />
        </svg>
      ),
      statValue: 15.65,
      statLabel: 'С прошлого месяца',
    },
    {
      color: 'gray',
      value: '0 ₸',
      label: 'Чистая прибыль ',
      icon: (
        <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_121_4727)">
            <path
              d="M22.9993 12.0935V8.00046C22.9993 6.04455 21.6279 4.28172 19.1379 3.03681C16.9417 1.93875 14.0515 1.33398 10.9997 1.33398C7.94749 1.33398 5.05718 1.93875 2.86106 3.03681C0.371237 4.28172 -1 6.04455 -1 8.00046V13.3336V18.6665V23.9996C-1 25.9556 0.371237 27.7184 2.86115 28.9634C5.05727 30.0614 7.94758 30.6661 10.9998 30.6661C13.3249 30.6661 15.5414 30.3192 17.4563 29.6614C18.7218 30.3036 20.1523 30.6661 21.6659 30.6661C26.8128 30.6661 31.0001 26.4788 31.0001 21.3319C31 16.6378 27.5167 12.7425 22.9993 12.0935ZM1.66691 17.6029C2.03059 17.8487 2.42849 18.0809 2.86115 18.2972C5.05727 19.3953 7.94766 20 10.9998 20C11.4811 20 11.9595 19.9841 12.4335 19.9542C12.3667 20.4039 12.3316 20.864 12.3316 21.332C12.3316 21.7675 12.3623 22.1961 12.4203 22.6158C11.9518 22.6493 11.4772 22.6662 10.9998 22.6662C5.65812 22.6663 1.66691 20.5546 1.66691 18.6666V17.6029ZM13.2976 17.2001C12.5489 17.2882 11.7789 17.333 10.9997 17.333C5.65812 17.3331 1.66691 15.2216 1.66691 13.3336V12.2698C2.03059 12.5156 2.42849 12.7479 2.86115 12.9641C5.05727 14.0623 7.94766 14.6669 10.9998 14.6669C12.6419 14.6669 14.2359 14.4904 15.7015 14.1576C14.7028 14.9893 13.8802 16.025 13.2976 17.2001ZM10.9997 4.0009C16.3411 4.0009 20.3323 6.11247 20.3323 8.00046C20.3323 9.88846 16.3411 12.0001 10.9997 12.0001C5.65812 12.0001 1.66691 9.88846 1.66691 8.00046C1.66691 6.11247 5.65812 4.0009 10.9997 4.0009ZM1.66691 23.9997V22.9361C2.03059 23.1819 2.42849 23.4141 2.86115 23.6303C5.05727 24.7284 7.94758 25.3332 10.9998 25.3332C11.7358 25.3332 12.4667 25.297 13.1842 25.227C13.591 26.1093 14.1313 26.9179 14.7793 27.6263C13.591 27.8697 12.3044 27.9993 10.9997 27.9993C5.65812 27.9993 1.66691 25.8876 1.66691 23.9997ZM21.6658 27.9993C17.9895 27.9993 14.9985 25.0083 14.9985 21.332C14.9985 17.6661 17.9728 14.6823 21.6349 14.6655C21.6453 14.6658 21.6553 14.6671 21.6658 14.6671C21.6763 14.6671 21.6863 14.6658 21.6967 14.6655C25.3589 14.6823 28.3331 17.6661 28.3331 21.332C28.3331 25.0083 25.3421 27.9993 21.6658 27.9993Z"
              fill="#ADB4D2"
            />
          </g>
          <defs>
            <clipPath id="clip0_121_4727">
              <rect width="32" height="32" fill="white" transform="translate(-1)" />
            </clipPath>
          </defs>
        </svg>
      ),
      statValue: 15.65,
      statLabel: 'С прошлого месяца',
    },
    {
      color: 'blue-dark',
      value: '0 ₸',
      label: 'Реализовано товаров',
      icon: (
        <svg width="28" height="32" viewBox="0 0 28 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.0009 1.73158L24.8573 6.26748C26.7138 7.33928 27.8573 9.32004 27.8573 11.4636V20.5354C27.8573 22.679 26.7138 24.6598 24.8573 25.7316L17.0009 30.2675C15.1445 31.3393 12.8573 31.3393 11.0009 30.2675L3.14453 25.7316C1.28812 24.6598 0.144531 22.679 0.144531 20.5354V11.4636C0.144531 9.32004 1.28812 7.33928 3.14453 6.26748L11.0009 1.73158C12.8573 0.659785 15.1445 0.659785 17.0009 1.73158ZM12.2098 3.35116L12.0009 3.46363L4.14453 7.99953C2.97206 8.67646 2.2261 9.89725 2.15083 11.239L2.14453 11.4636V20.5354C2.14453 21.8893 2.82879 23.1457 3.95311 23.8817L4.14453 23.9995L12.0009 28.5354C13.1698 29.2103 14.5948 29.2478 15.7921 28.6479L16.0009 28.5354L23.8573 23.9995C25.0298 23.3226 25.7758 22.1018 25.8511 20.7601L25.8573 20.5354V11.4636C25.8573 10.1098 25.1731 8.85336 24.0488 8.11731L23.8573 7.99953L16.0009 3.46363C14.8321 2.7888 13.4071 2.75131 12.2098 3.35116ZM21.0213 11.5569C21.2805 12.0445 21.0954 12.65 20.6078 12.9093L14.9894 15.8962L14.9895 22.6651C14.9895 23.2174 14.5417 23.6651 13.9895 23.6651C13.4372 23.6651 12.9895 23.2174 12.9895 22.6651L12.9894 15.8962L7.37115 12.9093C6.88351 12.65 6.69839 12.0445 6.95767 11.5569C7.21696 11.0693 7.82245 10.8842 8.31009 11.1434L13.9894 14.1622L19.6688 11.1434C20.1565 10.8842 20.762 11.0693 21.0213 11.5569Z"
            fill="#3168D3"
          />
        </svg>
      ),

      statValue: 15.65,
      statLabel: 'С прошлого месяца',
    },
    {
      color: 'danger',
      value: '0 ₸',
      label: 'Кол-во продаж',
      icon: (
        <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M4.75229 19.6962L8.79791 14.7959C8.91187 14.5965 8.91187 14.3686 8.79791 14.1976L4.75229 9.35428C4.7238 9.2973 4.69531 9.24032 4.69531 9.18334C4.69531 9.0124 4.83776 8.89844 4.98021 8.89844H10.8777C11.0486 8.89844 11.1911 8.95542 11.2766 9.09787L15.5216 14.2261C15.6356 14.3971 15.6356 14.625 15.5216 14.8244L11.305 19.9526C11.2196 20.0666 11.0486 20.1521 10.9062 20.1521H5.00871C4.83776 20.1521 4.7238 20.0381 4.7238 19.8672C4.69531 19.8102 4.7238 19.7247 4.75229 19.6962Z"
            fill="#D33131"
          />
          <path
            d="M13.7554 19.6962L17.7725 14.7959C17.8865 14.5965 17.8865 14.3686 17.7725 14.1976L13.7269 9.35428C13.6984 9.2973 13.6699 9.24032 13.6699 9.18334C13.6699 9.0124 13.8124 8.89844 13.9548 8.89844H19.8523C20.0232 8.89844 20.1657 8.95542 20.2512 9.09787L24.4962 14.2261C24.6102 14.3971 24.6102 14.625 24.4962 14.8244L20.2797 19.9526C20.1942 20.0666 20.0232 20.1521 19.8808 20.1521H13.9833C13.8124 20.1521 13.6984 20.0381 13.6984 19.8672C13.6984 19.8102 13.7269 19.7247 13.7554 19.6962Z"
            fill="#D33131"
          />
        </svg>
      ),
      statValue: 15.65,
      statLabel: 'С прошлого месяца',
    },
  ];
  const list2 = [
    {
      label: 'Всего продаж, ₸',
      value: '0 ₸',
    },
    {
      label: 'Реализовано товаров, шт',
      value: '0 ₸',
    },
    {
      label: 'Топ 5 городов по продажам, ₸',
      value: '0 ₸',
    },
  ];
  const list3 = [
    {
      label: 'Отмены, ₸',
      value: '0 ₸',
    },
    {
      label: 'Количество отмен, шт',
      value: '0 ₸',
    },
    {
      label: 'Топ 5 городов по отменам, ₸',
      value: '0 ₸',
    },
  ];
  return (
    <>
      <StatCardList list={list1} />
      <StatInlineList list={list2} className="container-fluid" />
      <StatInlineList list={list3} className="mt-20 container-fluid" />
    </>
  );
};

export default Tab1;
