import React from 'react';
import styles from './ChatMessage.module.scss';
import clsx from 'clsx';
const ChatMessage = ({ self, text, name, loading }) => {
  return (
    <>
      <div className={clsx(styles.wrap)}>
        {self ? <div className={clsx(styles.avatar)}>{name}</div> : <div className={clsx(styles.logo)}></div>}
        {loading ? (
          <div className={clsx(styles.loading)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        ) : (
          <div className={clsx(styles.text)}>{text}</div>
        )}
      </div>
    </>
  );
};

export default ChatMessage;
