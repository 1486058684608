import React from 'react';
import styles from './StatCard.scss';
import clsx from 'clsx';
const StatCard = ({ color = 'primary', value, label, icon = 'uil-shopping-cart-alt', iconSize = '24px', statValue, statLabel }) => {
  return (
    <>
      <div class="col-xxl-3 col-sm-6 mb-25">
        <div class="ap-po-details ap-po-details--2 p-25 radius-xl d-flex justify-content-between">
          <div class="overview-content w-100">
            <div class=" ap-po-details-content d-flex flex-wrap justify-content-between">
              <div class="ap-po-details__titlebar">
                <h1>{value}</h1>
                <p>{label}</p>
              </div>
              <div class="ap-po-details__icon-area">
                <div class={`svg-icon order-bg-opacity-${color} `}>
                  {icon}
                  {/* <i class={`uil ${icon}`} style={{ fontSize: iconSize }}></i> */}
                </div>
              </div>
            </div>
            <div class="ap-po-details-time">
              <span className={clsx(statValue >= 0 ? 'color-success' : 'color-danger')}>
                <i className={clsx('las', statValue >= 0 ? ' la-arrow-up' : ' la-arrow-down')}></i>
                <strong>{statValue}%</strong>
              </span>
              <small>{statLabel}</small>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatCard;
