import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import OutsideClickHandler from 'react-outside-click-handler';
import styles from './Calendar.scss';
// import * as rdrLocales from 'react-date-range/dist/locale';
// import { DateRangePicker, defaultStaticRanges, SingleDatePicker } from 'react-date-range';
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css'; // theme css fi
// import Input from '../../Input/Input';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { SingleDatePicker, DateRangePicker } from 'react-dates';

const Calendar = ({
  placeholder,
  setValue = () => {},
  defaultValue = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  },
}) => {
  // const formText = useForm();
  // const [selectionRange, setSelectionRange] = useState([defaultValue]);
  // function handleSelect(date) {
  //   //
  //   setSelectionRange([date.selection]);
  // }
  // const [show, setShow] = useState(false);

  // useEffect(() => {
  //   let startDate = defaultValue.startDate;
  //   let endDate = defaultValue.endDate;
  //   // startDate.setMonth(startDate.getMonth() - 1);
  //   endDate.setUTCHours(0, 0, 0, 0);
  //   startDate.setUTCHours(0, 0, 0, 0);

  //   setSelectionRange([
  //     {
  //       startDate,
  //       endDate,
  //       key: 'selection',
  //     },
  //   ]);

  //   setValue({
  //     startDate: startDate,
  //     endDate,
  //   });

  //   const startFormat = moment(startDate).format('DD.MM.YYYY');
  //   const endFormat = moment(endDate).format('DD.MM.YYYY');
  //   if (startFormat == endFormat) {
  //     formText.setValue('date', `${startFormat}`);
  //   } else {
  //     formText.setValue('date', `${startFormat} - ${moment(endDate).format('DD.MM.YYYY')}`);
  //   }
  // }, []);

  // return (
  //   <div style={{ position: 'relative' }}>
  //     <div style={{ position: 'relative' }}>
  //       <Input style={{ height: '38px', width: '190px', paddingRight: '0' }} rightIcon={'lnr-calendar-full btn-icon-wrapper'} search placeholder={placeholder} form={formText} name="date" />
  //       <div
  //         onClick={() => {
  //           setShow(true);
  //         }}
  //         style={{
  //           position: 'absolute',
  //           top: 0,
  //           left: 0,
  //           right: 0,
  //           bottom: 0,
  //           width: '100%',
  //           height: '100%',
  //         }}></div>
  //     </div>{' '}
  //     {show && (
  //       <OutsideClickHandler
  //         onOutsideClick={() => {
  //           setShow(false);
  //         }}>
  //         <div
  //           style={{
  //             position: 'absolute',
  //             top: 'calc(100% + 40px)',
  //             zIndex: '10',
  //             left: 0,
  //             background: '#fff',
  //           }}>
  //           {' '}
  //           <SingleDatePicker
  //             staticRanges={[
  //               {
  //                 ...defaultStaticRanges[0],
  //                 label: 'Сегодня',
  //               },
  //               {
  //                 ...defaultStaticRanges[1],
  //                 label: 'Вчера',
  //               },
  //               {
  //                 ...defaultStaticRanges[2],
  //                 label: 'Неделя',
  //               },
  //               {
  //                 ...defaultStaticRanges[4],
  //                 label: 'Месяц',
  //               },
  //             ]}
  //             // dateDisplayFormat="dd.MM.yyyy"
  //             className="custom-date-range"
  //             editableDateInputs={true}
  //             // locale={rdrLocales.ru}
  //             ranges={selectionRange}
  //             onChange={handleSelect}
  //             color="#0D6EFD"
  //           />
  //           <button
  //             onClick={() => {
  //               const startFormat = moment(selectionRange[0].startDate).format('DD.MM.YYYY');
  //               const endFormat = moment(selectionRange[0].endDate).format('DD.MM.YYYY');
  //               if (startFormat == endFormat) {
  //                 formText.setValue('date', `${startFormat}`);
  //               } else {
  //                 formText.setValue('date', `${startFormat} - ${moment(selectionRange[0].endDate).format('DD.MM.YYYY')}`);
  //               }

  //               setValue({
  //                 startDate: selectionRange[0].startDate,
  //                 endDate: selectionRange[0].endDate,
  //               });
  //               setShow(false);
  //             }}
  //             className="  btn btn-primary"
  //             style={{
  //               width: 'calc(100% - 32px)',
  //               height: '37px',
  //               margin: '0 16px 16px 16px',
  //             }}>
  //             Применить фильтр
  //           </button>
  //           <button
  //             onClick={() => {
  //               formText.setValue('date', null);
  //               setSelectionRange([
  //                 {
  //                   startDate: new Date(),
  //                   endDate: new Date(),
  //                   key: 'selection',
  //                 },
  //               ]);
  //               setValue(null);
  //               setShow(false);
  //             }}
  //             className="  btn-transition btn btn-outline-primary"
  //             style={{
  //               width: 'calc(100% - 32px)',
  //               height: '37px',
  //               margin: '0 16px 16px 16px',
  //             }}>
  //             Сбросить фильтр
  //           </button>
  //         </div>
  //       </OutsideClickHandler>
  //     )}{' '}
  //   </div>
  // );
  const [date, setDate] = useState(null);
  const [focus, setFocus] = useState(false);
  console.log(date, focus);

  return (
    <>
      <DateRangePicker
        startDate={date?.startDate} // momentPropTypes.momentObj or null,
        startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
        endDate={date?.endDate} // momentPropTypes.momentObj or null,
        endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
        onDatesChange={({ startDate, endDate }) => setDate({ startDate, endDate })} // PropTypes.func.isRequired,
        focusedInput={focus} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={(focusedInput) => setFocus(focusedInput)} // PropTypes.func.isRequired,
      />
    </>
  );
};

export default Calendar;
