import { createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from 'axios';

export const initStateImportDamping = {
  importDamping: { data: null, loading: false, error: null },
};

export const importDamping = createAsyncThunk('user/importDamping', async (data, { rejectWithValue, fulfillWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });
  return await axios
    .post(`${process.env.REACT_APP_SERVER_API}/kaspi-products/upload/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        // Accept: '*/*',
        // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((res) => {
      return rejectWithValue(res.response.data);
    });
});

export const reducerImportDamping = {
  [importDamping.pending]: (state) => {
    state.importDamping.loading = true;
  },
  [importDamping.fulfilled]: (state, action) => {
    state.importDamping.loading = false;
    state.importDamping.data = action.payload;
    state.importDamping.error = null;
  },
  [importDamping.rejected]: (state, action) => {
    state.importDamping.loading = false;
    state.importDamping.error = action.payload;
  },
};
