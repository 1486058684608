import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import StepRegistration from '../../../components/site/StepRegistration/StepRegistration';
import StepInputPhone from '../../../components/site/StepInputPhone/StepInputPhone';
import StepInputSms from '../../../components/site/StepInputSms/StepInputSms';
import { useSelector } from 'react-redux';
const RegistrationPage = () => {
  const { stepRegistration } = useSelector((state) => state.app);
  return stepRegistration == 0 ? <StepInputPhone /> : stepRegistration == 1 ? <StepInputSms /> : <StepRegistration />;
};

export default RegistrationPage;
