import React from 'react';
import styles from './ChatInput.module.scss';
import clsx from 'clsx';
const ChatInput = ({ form, name, onClick, disabled }) => {
  return (
    <>
      <div className={clsx(styles.wrap)}>
        <input type="text" className={clsx(styles.input)} placeholder="Введите запрос" {...form.register(name, { required: true })} autoComplete="off" />
        <div className={clsx(styles.send, disabled && styles.sendDisabled)} {...(!disabled && { onClick })}></div>
      </div>
    </>
  );
};

export default ChatInput;
