import { createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from 'axios';

export const initStateCreateChat = {
  createChat: { data: null, loading: false, error: null },
};

export const createChat = createAsyncThunk('tariff/createChat', async (data, { rejectWithValue, fulfillWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });
  return await axios
    .post(`${process.env.REACT_APP_SERVER_API}/ai-chat/`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        // Accept: '*/*',
        // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        // Connection: 'keep-alive',
      },
    })
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((res) => {
      return rejectWithValue(res.response.data);
    });
});

export const reducerCreateChat = {
  [createChat.pending]: (state) => {
    state.createChat.loading = true;
  },
  [createChat.fulfilled]: (state, action) => {
    state.createChat.loading = false;
    state.createChat.data = action.payload;
    state.createChat.error = null;
  },
  [createChat.rejected]: (state, action) => {
    state.createChat.loading = false;
    state.createChat.data = action?.payload;
    state.createChat.error = true;
  },
};
