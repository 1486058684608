import React, { useId, useState } from 'react';
import styles from './Input.scss';
import { Controller } from 'react-hook-form';
import { PatternFormat, NumericFormat } from 'react-number-format';
import clsx from 'clsx';
import Select from 'react-select';
const Input = ({
  options = [
    { label: 'Option 1', value: '1' },
    { label: 'Option 2', value: '2' },
    { label: 'Option 3', value: '3' },
  ],
  small,
  classInput,
  style = {},
  styleError,
  styleLabel,
  disabled,
  label,
  form,
  name,
  rules = { required: { message: 'Обязательное поле', value: true } },
  styleWrap = {},
  placeholder,
  type = 'text',
  classWrap = '',
  toolTip = false,
  value,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const error = form?.formState?.errors?.[name]?.message;
  const id = useId();
  return (
    <>
      <div class={`form-group ${classWrap}`} style={styleWrap}>
        {label && type !== 'checkbox' && (
          <label for={name} class="d-flex align-items-center" style={{ textTransform: 'none', ...styleLabel }}>
            {label}
            {toolTip && <img style={{ display: 'block', marginLeft: '7px' }} src="/img/info-circle.svg" />}
          </label>
        )}
        <div class="position-relative">
          {type == 'phone' ? (
            <PatternFormat
              className="form-control text-center"
              format="+ 7 (###) ###-##-##"
              value={form.watch(name)}
              mask="*"
              type="text"
              placeholder="+ 7 (123) 456-78-90"
              is
              onValueChange={(val) => {
                form.setValue(`${name}Formatted`, val.formattedValue);
                form.setValue(name, val.floatValue);
              }}
            />
          ) : type == 'sms' ? (
            <PatternFormat
              className={`form-control form-control-sms ${classInput}`}
              format="# # # # # #"
              value={form.watch(name)}
              mask="*"
              type="text"
              placeholder="9 5 4 5 8 2"
              is
              style={style}
              onValueChange={(val) => {
                form.setValue(name, val.floatValue);
              }}
            />
          ) : type == 'select' ? (
            <Select classNamePrefix={small ? 'custom-select-small' : 'custom-select'} value={''} options={options} placeholder={placeholder} />
          ) : type == 'checkbox' ? (
            <div class={clsx('checkbox-theme-default custom-checkbox', classInput && classInput)} style={style}>
              <input class="checkbox" type="checkbox" {...form?.register(name)} id={id} />
              <label for={id}>
                <span class="checkbox-text">{label}</span>
              </label>
            </div>
          ) : (
            <>
              {' '}
              <input
                style={style}
                autoComplete="off"
                disabled={disabled}
                id={name}
                type={type == 'password' ? (showPassword ? 'text' : 'password') : type}
                class={clsx('form-control', classInput, small && 'form-control-small')}
                name="password"
                placeholder={placeholder}
                {...form.register(name, rules)}
              />
              {type == 'password' && (
                <div
                  class={`uil uil-eye-slash text-lighten fs-15 field-icon toggle-password2 ${showPassword ? 'uil-eye' : ''}`}
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}></div>
              )}
            </>
          )}
        </div>{' '}
        {error && (
          <p className={`error `} style={styleError}>
            {error}
          </p>
        )}
      </div>
    </>
  );
};

export default Input;
