import React, { useEffect, useState } from 'react';
import styles from './HomePage.module.scss';
import { useNavigate } from 'react-router';
import Calendar from '../../../components/site/common/Calendar/Calendar';
import clsx from 'clsx';
import Button from '../../../components/site/Button/Button';
import Tabs from '../../../components/site/common/Tabs/Tabs';
import Title from '../../../components/site/common/Title/Title';
import Tab1 from '../../../components/pages/Tab1/Tab1';
import StatInlineList from '../../../components/site/common/StatInlineList/StatInlineList';
import Tab2 from '../../../components/pages/Tab2/Tab2';
import Tab3 from '../../../components/pages/Tab3/Tab3';
import Tab4 from '../../../components/pages/Tab4/Tab4';
import TelegramJoin from '../../../components/pages/TelegramJoin/TelegramJoin';
import Tab5 from '../../../components/pages/Tab5/Tab5';
import Tab6 from '../../../components/pages/Tab6/Tab6';
import APITokenShop from '../../../components/pages/APITokenShop/APITokenShop';
const HomePage = () => {
  const tabs = [
    {
      label: 'Сводные данные',
      value: 1,
      component: (
        <>
          {' '}
          <Tab1 />
        </>
      ),
    },
    {
      label: 'Продажи',
      value: 2,
      component: (
        <>
          {' '}
          <Tab2 />
        </>
      ),
    },

    // {
    //   label: 'ABC анализ продаж',
    //   value: 6,
    //   component: (
    //     <>
    //       {' '}
    //       <Tab6 />
    //     </>
    //   ),
    // },
  ];
  const [activeTab, setActiveTab] = useState(1);
  return (
    <>
      <TelegramJoin height={activeTab >= 4 ? '160px' : '0'} />

      <div className={clsx(styles.wrap)}>
        <div className={clsx(styles.calendarWrap)}>
          <Calendar
            placeholder={'Дата'}
            setValue={(val) => {
              console.log(val);
              // setSearch({ ...search, [headKey]: val });
            }}
          />
          <Button>Искать</Button>
        </div>{' '}
        {/* <APITokenShop /> */}
        <Title className="mb-20 ">Данные по магазину</Title>
        <Tabs list={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      </div>
    </>
  );
};

export default HomePage;
