import React, { useState } from 'react';
import './TelegramJoin.scss';
import AnimateHeight from 'react-animate-height';
const TelegramJoin = ({}) => {
  return (
    <>
      {' '}
      <div className="order-bg-opacity-primary d-flex p-25 align-items-center mt-20" style={{ borderRadius: '8px' }}>
        <img src="/img/telegram.png" alt="" className="" />
        <div className="" style={{ marginLeft: '38px' }}>
          <div className="h1" style={{ lineHeight: '47.2px' }}>
            Присоеденяйтесь к нам в телеграм
          </div>
          <div className="fs-14 " style={{ color: '#000' }}>
            Там мы проводим акции, даем лайфхаки, и раскрываем секреты Kaspi, публикуем важные новости и делимся опытом кркутых селлеров
          </div>
        </div>
      </div>
    </>
  );
};

export default TelegramJoin;
