import React, { useEffect, useRef, useState } from 'react';
import './OpportunityPage.scss';
import Input from '../../../components/site/Input/Input';
import { useForm } from 'react-hook-form';
import Button from '../../../components/site/Button/Button';
import TableOpportunity from '../../../components/site/common/TableOpportunity/TableOpportunity';
import FilterOpportunity from '../../../components/site/common/FilterOpportunity/FilterOpportunity';
import ModalMassiveChanges from '../ModalMassiveChanges/ModalMassiveChanges';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getDamping } from '../../../redux/actions/damping/getDamping';
import Loading from '../../../components/site/Loading/Loading';
import { toast } from 'react-toastify';
import { addDamping } from '../../../redux/actions/damping/addDamping';
import { resetAddDamping, resetExportDamping, resetImportDamping, resetRemoveDamping } from '../../../redux/slices/damping.slice';
import { removeDamping } from '../../../redux/actions/damping/removeDamping';
import { exportDamping } from '../../../redux/actions/damping/exportDamping';
import moment from 'moment';
import { importDamping } from '../../../redux/actions/damping/importDamping';
const OpportunityPage = () => {
  const form = useForm({
    defaultValues: {
      products: [],
    },
  });
  const [showModalMassiveChanges, setShowModalMassiveChanges] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    getDamping: { data: dampingData, loading: dampingLoading },
    removeDamping: { data: removeDampingData, loading: removeDampingLoading },
    addDamping: { data: addDampingData, loading: addDampingLoading },
    exportDamping: { data: exportDampingData, loading: exportDampingLoading },
    importDamping: { data: importDampingData, loading: importDampingLoading },
  } = useSelector((state) => state.damping);
  useEffect(() => {
    dispatch(getDamping());
  }, []);
  const getSelectedCity = (prods) => {
    let selectedCity = 0;
    prods?.map((prod) => {
      selectedCity += prod?.cityList?.filter((city) => city?.isSelected)?.length;
    });
    return !!selectedCity;
  };
  const [isSomeCitySelected, setIsSomeCitySelected] = useState(false);
  React.useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      if (name?.includes('isSelect')) {
        setIsSomeCitySelected(getSelectedCity(value.products));
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);

  const onBotEnable = (data) => {
    try {
      const postData = [];
      let quantityCity = [];
      data?.products?.map((prod) => {
        const selectedCity = prod?.cityList
          ?.filter((city) => city?.isSelected && !city?.damping_info_id)
          ?.map((city) => {
            if ((typeof city?.max_price == 'number' && typeof city?.min_price == 'number' && typeof city?.step_price == 'number') || typeof city?.quantity == 'number') {
              if (city?.quantity) {
                quantityCity.push({
                  quantity: city?.quantity,
                  city_name: city?.name,
                });
              }
              if (city?.max_price && city?.min_price && city?.step_price) {
                return {
                  max_price: city?.max_price,
                  min_price: city?.min_price,
                  step_price: city?.step_price,
                  city_name: city?.name,
                };
              } else {
                return;
              }
            } else {
              toast.error('Заполните поле "остатки" или все остальные поля', { autoClose: 3000 });
              throw new Error();
            }
          })
          ?.filter((item) => item);

        if (selectedCity?.length) {
          postData.push({
            product_id: prod?.productId,
            damping_info: selectedCity,
            quantity: quantityCity,
          });
        }
      });

      if (postData?.length) {
        dispatch(addDamping(postData));
      }
    } catch (error) {}
  };
  const onBotDisabled = (data) => {
    try {
      let postData = [];
      data?.products?.map((prod) => {
        const selectedCity = prod?.cityList?.filter((city) => city?.isSelected && city?.damping_info_id)?.map((city) => city?.damping_info_id);

        if (selectedCity?.length) {
          postData = [...postData, ...selectedCity];
        }
      });
      console.log(postData);
      if (postData?.length) {
        dispatch(removeDamping(postData));
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (addDampingData) {
      toast.success('Данные обновлены', { autoClose: 3000 });
      dispatch(resetAddDamping());
      dispatch(getDamping());
    }
  }, [addDampingData]);
  useEffect(() => {
    if (removeDampingData) {
      toast.success('Данные обновлены', { autoClose: 3000 });
      dispatch(resetRemoveDamping());
      dispatch(getDamping());
    }
  }, [removeDampingData]);
  useEffect(() => {
    if (importDampingData) {
      toast.success('Импорт успешно завершен', { autoClose: 3000 });
      dispatch(resetImportDamping());
    }
  }, [importDampingData]);

  const refFile = useRef();
  const onImport = () => {
    refFile.current.click();
  };
  const onExport = () => {
    dispatch(exportDamping());
  };

  useEffect(() => {
    if (exportDampingData) {
      toast.success('Файл скачен', { autoClose: 2000 });
      const url = window.URL.createObjectURL(new Blob([exportDampingData]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Репрайсер_${moment().format('DD.MM.YYYY_HH-mm').toString()}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.remove();
      dispatch(resetExportDamping());
    }
  }, [exportDampingData]);
  const onFileUpload = (event) => {
    const formData = new FormData();
    formData.append('excel_file', event.target.files[0]);
    dispatch(importDamping(formData));
  };
  return (
    <>
      <div class="row">
        <div class="col-lg-12">
          <div class="breadcrumb-main">
            <h4 class="text-capitalize breadcrumb-title">Товары</h4>
          </div>
        </div>
        {dampingLoading && !dampingData ? (
          <Loading isWhite />
        ) : (
          <div class="col-12">
            <div className="card card-default card-md " style={{ padding: '25px' }}>
              <div className="card-body" style={{ padding: '0' }}>
                {' '}
                <div className="basic-form-wrapper" style={{ maxWidth: 'none' }}>
                  <div>
                    <div className="form-basic">
                      <div className="" style={{ display: 'flex', alignItems: 'center' }}>
                        {' '}
                        {/* <Input
                        small
                        styleWrap={{ display: 'flex', alignItems: 'center' }}
                        styleLabel={{ marginBottom: '0', fontWeight: '400', marginRight: '8px', fontSize: '15px' }}
                        form={form}
                        type="text"
                        name={'email'}
                        label={'Категории'}
                        classWrap={''}
                        classInput=" form-control-lg"
                        placeholder={'Введите название'}
                      />{' '} */}
                        {/* <Input
                        small
                        styleWrap={{ display: 'flex', alignItems: 'center' }}
                        styleLabel={{ marginBottom: '0', fontWeight: '400', marginRight: '8px', fontSize: '15px' }}
                        options={[{ label: 'Активный' }, { label: 'Неактивный' }]}
                        form={form}
                        type="select"
                        name={'email'}
                        label={'Статус'}
                        classWrap={''}
                        classInput=" form-control-lg"
                        placeholder={''}
                      />{' '} */}
                        {/* <FilterOpportunity /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ width: '100%', backgroundColor: '#fff', position: 'sticky', top: '72px', zIndex: '10' }}>
                  <div className="d-flex align-items-center justify-content-start flex-wrap" style={{ gap: '10px', marginTop: '0px', maxWidth: '600px', paddingBottom: '5px', paddingTop: '5px', marginBottom: '20px' }}>
                    {' '}
                    {/* <Button
                    onClick={() => {
                      setShowModalMassiveChanges(true);
                    }}>
                    {' '}
                    Массовые изменения{' '}
                  </Button> */}
                    {/* <Button
                    onClick={() => {
                      navigate('/opportunity/add');
                    }}>
                    {' '}
                    Добавить товар{' '}
                  </Button> */}
                    {/* <Button color="danger" disabled={isEmptySelected}>
                    {' '}
                    Снять с продажи{' '}
                  </Button> */}
                    <Button type="outline" disabled={!isSomeCitySelected} onClick={form.handleSubmit(onBotEnable)}>
                      {' '}
                      Включить бота{' '}
                    </Button>
                    <Button type="outline" disabled={!isSomeCitySelected} color="danger" onClick={form.handleSubmit(onBotDisabled)}>
                      Выключить бота
                    </Button>
                    <input type="file" style={{ display: 'none' }} ref={refFile} accept=".xls,.xlsx" onChange={onFileUpload} />
                    <Button color="warning" type="outline" onClick={onImport}>
                      {' '}
                      Импорт
                    </Button>
                    <Button type="outline" color="success" onClick={onExport}>
                      Экспорт
                    </Button>{' '}
                  </div>
                </div>
                <TableOpportunity form={form} />
              </div>
            </div>
            {(addDampingLoading || dampingLoading || exportDampingLoading || importDampingLoading) && <Loading isTransparent />}
          </div>
        )}

        <ModalMassiveChanges
          show={showModalMassiveChanges}
          onClose={() => {
            setShowModalMassiveChanges(false);
          }}
        />
      </div>
    </>
  );
};

export default OpportunityPage;
