import React from 'react';
import './StatInlineItem.scss';
import clsx from 'clsx';
const StatInlineItem = ({ label, value, caption, bigMargin }) => {
  return (
    <div>
      <div className="color-dark fw-600">{label}</div>
      <div className={clsx(' color-primary fw-500', bigMargin && 'mt-15 mb-15')} style={{ fontSize: '48px' }}>
        {value}
      </div>
      <div className="color-dark fw-600 mt-10">{caption}</div>
    </div>
  );
};

export default StatInlineItem;
