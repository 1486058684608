import { createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from 'axios';

export const initStateRemoveDamping = {
  removeDamping: { data: null, loading: false, error: null },
};

export const removeDamping = createAsyncThunk('user/removeDamping', async (data, { rejectWithValue, fulfillWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });
  return await axios
    .delete(
      `${process.env.REACT_APP_SERVER_API}/kaspi-products/damping/`,

      {
        data: { damping_info_ids: data },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((res) => {
      return rejectWithValue(res.response.data);
    });
});

export const reducerRemoveDamping = {
  [removeDamping.pending]: (state) => {
    state.removeDamping.loading = true;
  },
  [removeDamping.fulfilled]: (state, action) => {
    state.removeDamping.loading = false;
    state.removeDamping.data = true;
    state.removeDamping.error = null;
  },
  [removeDamping.rejected]: (state, action) => {
    state.removeDamping.loading = false;
    state.removeDamping.error = action.payload;
  },
};
