import { createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from 'axios';

export const initStateUserConfirmCode = {
  userConfirmCode: { data: null, loading: false, error: null },
};

export const userConfirmCode = createAsyncThunk('user/userConfirmCode', async (data, { rejectWithValue, fulfillWithValue }) => {
  return await axios
    .post(`${process.env.REACT_APP_SERVER_API}/auth/register/send-code/confirm/`, data, {
      Accept: '*/*',
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      Connection: 'keep-alive',
    })
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((res) => {
      return rejectWithValue(res.response.data);
    });
});

export const reducerUserConfirmCode = {
  [userConfirmCode.pending]: (state) => {
    state.userConfirmCode.loading = true;
  },
  [userConfirmCode.fulfilled]: (state, action) => {
    state.userConfirmCode.loading = false;
    state.userConfirmCode.data = action.payload;
    state.userConfirmCode.error = null;
  },
  [userConfirmCode.rejected]: (state, action) => {
    state.userConfirmCode.loading = false;
    state.userConfirmCode.error = action.payload;
  },
};
