import React, { useEffect, useState } from 'react';
import styles from './ReferralCode.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import Button from '../Button/Button';
import { referralGenerate } from '../../../redux/actions/referral/referralGenerate';
import Loading from '../Loading/Loading';
import { resetReferralGenerate } from '../../../redux/slices/referral.slice';
import { toast } from 'react-toastify';
import { userProfile } from '../../../redux/actions/user/userProfile';
import { useMediaQuery } from '../../../utils/useMediaQuery';
const ReferralCode = () => {
  const isMobile = useMediaQuery('(max-width: 700px)');
  const {
    userProfile: { data: userData, loading: userLoading, error: userError },
  } = useSelector((state) => state.user);
  const {
    referralGenerate: { data: referralGenerateData, loading: referralGenerateLoading },
  } = useSelector((state) => state.referral);
  const dispatch = useDispatch();

  useEffect(() => {
    if (referralGenerateData) {
      toast.success('Промокод сгенерирован', { autoClose: 2000 });
      dispatch(userProfile());
      dispatch(resetReferralGenerate());
    }
  }, [referralGenerateData]);
  const [showCopy, setShowCopy] = useState(false);
  return (
    <>
      <div className={clsx(styles.wrap)}>
        {userData?.referral_code && (
          <div className={clsx(styles.wrapCode)}>
            {' '}
            <div
              className={clsx(styles.code)}
              onClick={() => {
                if (userData?.referral_code) {
                  window.navigator.clipboard.writeText(userData?.referral_code);
                  setShowCopy(true);
                  setTimeout(() => {
                    setShowCopy(false);
                  }, 2000);
                }
              }}>
              {userData?.referral_code}{' '}
              <div className={clsx(styles.copy)}>
                {' '}
                <i class="uil uil-copy"></i>
              </div>
            </div>
            <div
              className={clsx(styles.generateNew)}
              onClick={() => {
                dispatch(referralGenerate());
              }}>
              Сгенерировать новый <i class="uil uil-refresh" style={{ marginLeft: '3px', marginTop: '2px' }}></i>
            </div>
          </div>
        )}

        {!userData?.referral_code && (
          <Button
            onClick={() => {
              dispatch(referralGenerate());
            }}
            color="success"
            type="outline"
            style={{ borderRadius: '100px', fontSize: '14px', height: '36px', padding: `0 5px 0 ${!isMobile ? '15px' : '10px'}` }}>
            {' '}
            Сгенерировать {!isMobile ? 'промокод' : ''} <i class="uil uil-refresh" style={{ marginLeft: '5px', marginTop: '2px' }}></i>
          </Button>
        )}
        <div className={clsx(styles.message, showCopy && styles.messageShow)}>Скопировано </div>
      </div>
      {referralGenerateLoading && <Loading />}
    </>
  );
};

export default ReferralCode;
