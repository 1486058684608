import React, { useEffect } from 'react';
import './StartPage.scss';
import { useNavigate } from 'react-router';
const StartPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate('/overview');
  }, []);
  return <></>;
};

export default StartPage;
