import React, { useState } from 'react';
import './OpportunityAddProductPage.scss';
import Input from '../../../components/site/Input/Input';
import { useForm } from 'react-hook-form';
import Button from '../../../components/site/Button/Button';
import OpportunitySearchItem from '../OpportunitySearchItem/OpportunitySearchItem';
import { useNavigate } from 'react-router';
const OpportunityAddProductPage = () => {
  const form = useForm({ defaultValues: { search: '' } });
  const list = [
    {
      name: 'Apple iPhone 15 Pro Max 256Gb серый',
      image: 'https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/iphone-card-40-iphone15prohero-202309?wid=680&hei=528&fmt=p-jpg&qlt=95&.v=1693086290312',
    },
    { name: 'Apple iPhone 15 Pro Max 256Gb розовый', image: 'https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/iphone-card-40-iphone15hero-202309?wid=680&hei=528&fmt=p-jpg&qlt=95&.v=1693086290559' },
    { name: 'Apple iPhone 15 Pro Max 256Gb голубой', image: 'https://store.storeimages.cdn-apple.com/4982/as-images.apple.com/is/iphone-card-40-iphone14-202209?wid=680&hei=528&fmt=p-jpg&qlt=95&.v=1693086290654' },
    { name: 'Xiaomi 14 12GB/512GB международная версия (матовый черный)', image: 'https://content2.onliner.by/catalog/device/header/ac009f440515d5e17988887f0786495d.jpg' },
    { name: 'Xiaomi Redmi 13C 8GB/256GB с NFC международная версия (белый)', image: 'https://content2.onliner.by/catalog/device/header/ee377045c8d166966c8ad3759290b8f8.jpg' },
    { name: 'Xiaomi Redmi A3 4GB/128GB международная версия (зеленый лес)', image: 'https://content2.onliner.by/catalog/device/header/160e6eb48b6cd5f33f01c24df8e9a7bb.jpg' },
  ];
  const navigate = useNavigate();
  const searchTerm = form.watch('search');
  return (
    <>
      <div class="row">
        <div className="col-12 mt-20">
          <div
            onClick={() => {
              navigate('/opportunity');
            }}
            class="color-dark"
            style={{ color: 'var(--color-dark)', fontSize: '14px', opacity: '0.5', userSelect: 'none', cursor: 'pointer' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="currentColor" style={{ marginBottom: '2.5px' }}>
              <path d="M17,11H9.41l3.3-3.29a1,1,0,1,0-1.42-1.42l-5,5a1,1,0,0,0-.21.33,1,1,0,0,0,0,.76,1,1,0,0,0,.21.33l5,5a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42L9.41,13H17a1,1,0,0,0,0-2Z"></path>
            </svg>
            Назад
          </div>
        </div>
        <div class="col-lg-12">
          {' '}
          <div class="breadcrumb-main" style={{ marginTop: '25px' }}>
            <h4 class="breadcrumb-title">Добавить товар</h4>
          </div>
        </div>{' '}
        <div className="col-12">
          <div className="card card-default card-md " style={{ padding: '25px' }}>
            <div className="card-body" style={{ padding: '0' }}>
              {' '}
              <div className="basic-form-wrapper" style={{ maxWidth: 'none', marginBottom: '40px' }}>
                <div>
                  <div className="form-basic">
                    <div className="" style={{ display: 'grid', alignItems: 'flex-end', gridTemplateColumns: '1fr 100px', columnGap: '10px' }}>
                      {' '}
                      <Input styleLabel={{ fontSize: '15px' }} form={form} type="text" label={'Поиск по названию'} name={'search'} classWrap={''} classInput=" form-control-lg" placeholder={'Например: apple iphone 13'} />
                      <Button style={{ height: '50px', alignSelf: 'end', width: '100%' }}>Поиск</Button>
                    </div>
                  </div>
                </div>
              </div>
              {searchTerm && (
                <>
                  {' '}
                  <div className="text-center color-dark  fw-600 mb-30" style={{ fontSize: '24px' }}>
                    <span style={{}}>Найдено товаров </span> <span className="color-primary">({list?.filter?.((item) => item?.name?.toLowerCase?.()?.includes(searchTerm.toLowerCase()))?.length})</span>
                  </div>
                  <div className="" style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill,minmax(170px,1fr))', gridGap: '30px' }}>
                    {' '}
                    {list
                      ?.filter?.((item) => item?.name?.toLowerCase?.().includes(searchTerm.toLowerCase()))
                      ?.map((item) => (
                        <OpportunitySearchItem {...item} />
                      ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OpportunityAddProductPage;
