import { createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from 'axios';

export const initStateUserResendCode = {
  userResendCode: { data: null, loading: false, error: null },
};

export const userResendCode = createAsyncThunk('user/userResendCode', async (data, { rejectWithValue, fulfillWithValue }) => {
  return await axios
    .post(
      `${process.env.REACT_APP_SERVER_API}/auth/register/send-code/re-send/`,
      { phone_number: data },
      {
        Accept: '*/*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        Connection: 'keep-alive',
      },
    )
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((res) => {
      return rejectWithValue(res.response.data);
    });
});

export const reducerUserResendCode = {
  [userResendCode.pending]: (state) => {
    state.userResendCode.loading = true;
  },
  [userResendCode.fulfilled]: (state, action) => {
    state.userResendCode.loading = false;
    state.userResendCode.data = action.payload;
    state.userResendCode.error = null;
  },
  [userResendCode.rejected]: (state, action) => {
    state.userResendCode.loading = false;
    state.userResendCode.error = action.payload;
  },
};
