import React, { useState } from 'react';
import './Tab4AddEdit.scss';
import Input from '../../site/Input/Input';
import { useForm } from 'react-hook-form';
import Button from '../../site/Button/Button';
import AnimateHeight from 'react-animate-height';
const Tab4AddEdit = ({ title, onClose }) => {
  const form = useForm();

  return (
    <>
      <div className="">
        <div className="">
          <div className="card card-default card-md " style={{ padding: '25px' }}>
            <div className="card-body" style={{ padding: '0' }}>
              {' '}
              <div className="h1 mb-30">{title}</div>
              <div className="basic-form-wrapper" style={{ maxWidth: 'none' }}>
                <div>
                  <div className="form-basic">
                    <div className="" style={{ display: 'grid', gridTemplateColumns: 'repeat(6, 1fr)', columnGap: '26px', rowGap: '25px' }}>
                      {' '}
                      <Input toolTip styleWrap={{ gridColumn: 'span 2' }} styleLabel={{ marginBottom: '14px', fontSize: '15px' }} form={form} type="select" name={'email'} label={'Организация'} classWrap={''} classInput=" form-control-lg" placeholder={'Выберите из списка'} />
                      <Input styleWrap={{ gridColumn: 'span 2' }} toolTip styleLabel={{ marginBottom: '14px', fontSize: '15px' }} form={form} type="select" name={'email'} label={'Контрагент'} classWrap={''} classInput="custom-input-home form-control-lg" placeholder={'Выберите из списка'} />
                      <Input tooltip styleWrap={{ gridColumn: 'span 2' }} styleLabel={{ marginBottom: '14px', fontSize: '15px' }} form={form} type="select" name={'email'} label={'Проект'} classWrap={''} classInput="custom-input-home form-control-lg" placeholder={'Выберите из списка'} />{' '}
                      <Input toolTip styleWrap={{ gridColumn: 'span 2' }} styleLabel={{ marginBottom: '14px', fontSize: '15px' }} form={form} type="select" name={'email'} label={'Склад'} classWrap={''} classInput=" form-control-lg" placeholder={'Выберите из списка'} />
                      <Input styleWrap={{ gridColumn: 'span 2' }} toolTip styleLabel={{ marginBottom: '14px', fontSize: '15px' }} form={form} type="select" name={'email'} label={'Договор'} classWrap={''} classInput="custom-input-home form-control-lg" placeholder={'Выберите из списка'} />
                      <Input toolTip styleWrap={{ gridColumn: 'span 2' }} styleLabel={{ marginBottom: '14px', fontSize: '15px' }} form={form} type="select" name={'email'} label={'Канал продаж'} classWrap={''} classInput="custom-input-home form-control-lg" placeholder={'Выберите из списка'} />{' '}
                      <Input toolTip styleWrap={{ gridColumn: 'span 3' }} styleLabel={{ marginBottom: '14px', fontSize: '15px' }} form={form} type="text" name={'email'} label={'Адрес доставки'} classWrap={''} classInput="custom-input-home form-control-lg" placeholder={'Адрес доставки'} />
                      <Input toolTip styleWrap={{ gridColumn: 'span 3' }} styleLabel={{ marginBottom: '14px', fontSize: '15px' }} form={form} type="text" name={'email'} label={'Комментарий'} classWrap={''} classInput="custom-input-home form-control-lg" placeholder={'abc'} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-end " style={{ marginTop: '25px' }}>
                {' '}
                <Button onClick={onClose} type="outline" style={{ marginRight: '12px' }}>
                  Отменить{' '}
                </Button>
                <Button onClick={onClose}>Сохранить </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Tab4AddEdit;
