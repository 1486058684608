import React from 'react';
import './Modal.scss';
import clsx from 'clsx';
const Modal = ({ width, height, children, show, onClose, close }) => {
  return (
    <>
      <div
        onClick={() => {
          onClose();
        }}
        style={{ opacity: 0, zIndex: '-1' }}
        className={clsx('overlay', show && 'overlay--active')}></div>
      <div className={clsx('modal-custom', show && 'modal-custom--active')} style={{ maxWidth: `${width}px`, ...(height && { maxHeight: `${height}px` }) }}>
        <div className="modal-custom-body"> {children}</div>
      </div>
    </>
  );
};

export default Modal;
