import { createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from 'axios';

export const initStateGetDamping = {
  getDamping: { data: null, loading: false, error: null },
};

export const getDamping = createAsyncThunk('user/getDamping', async (data, { rejectWithValue, fulfillWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });
  return await axios
    .get(`${process.env.REACT_APP_SERVER_API}/kaspi-products/`, {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: '*/*',
        'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      },
    })
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((res) => {
      return rejectWithValue(res.response.data);
    });
});

export const reducerGetDamping = {
  [getDamping.pending]: (state) => {
    state.getDamping.loading = true;
  },
  [getDamping.fulfilled]: (state, action) => {
    state.getDamping.loading = false;
    state.getDamping.data = action.payload;
    state.getDamping.error = null;
  },
  [getDamping.rejected]: (state, action) => {
    state.getDamping.loading = false;
    state.getDamping.error = action.payload;
  },
};
