import { useRoutes } from 'react-router';
import HomePage from './pages/site/HomePage/HomePage';
// import SiteLayout from './pages/site/SiteLayout/SiteLayout';
import LoginPage from './pages/site/LoginPage/LoginPage';
import LoginLayout from './components/site/LoginLayout/LoginLayout';
import RegistrationPage from './pages/site/RegistrationPage/RegistrationPage';
import ChangePasswordPage from './pages/site/ChangePasswordPage/ChangePasswordPage';
import HomeLayout from './components/site/HomeLayout/HomeLayout';
import OverviewPage from './pages/site/OverviewPage/OverviewPage';
import SettingPage from './pages/site/SettingPage/SettingPage';
import AccountPage from './pages/site/AccountPage/AccountPage';
import NotFoundPage from './pages/site/NotFoundPage/NotFoundPage';
import AccountLayout from './components/site/AccountLayout/AccountLayout';
import PricingPage from './pages/site/PricingPage/PricingPage';
import ResetPasswordPage from './pages/site/ResetPasswordPage/ResetPasswordPage';
import PasswordSentPage from './pages/site/PasswordSentPage/PasswordSentPage';
import ErrorPage from './pages/site/ErrorPage/ErrorPage';
import SuccessPage from './pages/site/SuccessPage/SuccessPage';
import StartPage from './pages/site/StartPage/StartPage';
import OpportunityPage from './pages/site/OpportunityPage/OpportunityPage';
import OpportunityAddProductPage from './pages/site/OpportunityAddProductPage/OpportunityAddProductPage';
import InputNewPasswordPage from './pages/site/InputNewPasswordPage/InputNewPasswordPage';
import CartPage from './pages/site/CartPage/CartPage';
import SmartSkladPage from './pages/site/SmartSkladPage/SmartSkladPage';
import ChatLayout from './components/site/ChatLayout/ChatLayout';
import ChatNewPage from './pages/site/ChatNewPage/ChatNewPage';
import ChatSinglePage from './pages/site/ChatSinglePage/ChatSinglePage';

function App() {
  let routes = useRoutes([
    {
      path: '*',
      element: (
        <HomeLayout>
          <NotFoundPage />
        </HomeLayout>
      ),
    },
    {
      path: '/home',
      element: (
        <HomeLayout>
          <HomePage />
        </HomeLayout>
      ),
    },
    {
      path: '/smart-sklad',
      element: (
        <HomeLayout>
          <SmartSkladPage />
        </HomeLayout>
      ),
    },
    {
      path: '/chat/new',
      element: (
        <ChatLayout>
          <ChatNewPage />
        </ChatLayout>
      ),
    },
    {
      path: '/chat/:id',
      element: (
        <ChatLayout>
          <ChatSinglePage />
        </ChatLayout>
      ),
    },
    {
      path: '/opportunity',
      element: (
        <HomeLayout>
          <OpportunityPage />
        </HomeLayout>
      ),
    },
    {
      path: '/opportunity/add',
      element: (
        <HomeLayout>
          <OpportunityAddProductPage />
        </HomeLayout>
      ),
    },
    {
      path: '/',
      element: (
        <HomeLayout>
          <StartPage />
        </HomeLayout>
      ),
    },
    {
      path: '/pricing',
      element: (
        <HomeLayout>
          <PricingPage />
        </HomeLayout>
      ),
    },
    {
      path: '/success',
      element: (
        <HomeLayout>
          <SuccessPage />
        </HomeLayout>
      ),
    },
    {
      path: '/error',
      element: (
        <HomeLayout>
          <ErrorPage />
        </HomeLayout>
      ),
    },
    {
      path: '/overview',
      element: (
        <HomeLayout>
          <OverviewPage />
        </HomeLayout>
      ),
    },
    {
      path: '/account/reset-password',
      element: (
        <HomeLayout>
          <AccountLayout title={'Сброс пароля'}>
            <ResetPasswordPage />
          </AccountLayout>
        </HomeLayout>
      ),
    },
    {
      path: '/password-sent',
      element: (
        <LoginLayout>
          <PasswordSentPage />
        </LoginLayout>
      ),
    },
    {
      path: '/account/edit',
      element: (
        <HomeLayout>
          <AccountLayout title={'Редактирование'}>
            <AccountPage />
          </AccountLayout>
        </HomeLayout>
      ),
    },
    {
      path: '/account/setting',
      element: (
        <HomeLayout>
          <AccountLayout title={'Настройки'}>
            <SettingPage />
          </AccountLayout>
        </HomeLayout>
      ),
    },
    {
      path: '/login',
      element: (
        <LoginLayout>
          <LoginPage />
        </LoginLayout>
      ),
    },
    {
      path: '/forget-password',
      element: (
        <LoginLayout>
          <ChangePasswordPage />
        </LoginLayout>
      ),
    },
    {
      path: '/cart',
      element: (
        <HomeLayout>
          <CartPage />
        </HomeLayout>
      ),
    },
    {
      path: '/password-reset/confirm/:uid/:token',
      element: (
        <LoginLayout>
          <InputNewPasswordPage />
        </LoginLayout>
      ),
    },
    {
      path: '/registration',
      element: (
        <LoginLayout>
          <RegistrationPage />
        </LoginLayout>
      ),
    },
  ]);

  return routes;
}

export default App;
