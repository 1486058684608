import React from 'react';
import styles from './Tariff.module.scss';
import { currencyFormat } from '../../../utils/currencyFormat';
import Loading from '../Loading/Loading';
import { useDispatch } from 'react-redux';
import { tariffPayment } from '../../../redux/actions/tariff/tariffPayment';
import { useNavigate } from 'react-router';
import { percentage } from '../../../pages/site/CartPage/CartPage';
const Tariff = ({ label, id, color = 'primary', price, name, details, active, period }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  return (
    <>
      {' '}
      <div class="col-xxl-3 col-lg-4 col-sm-6 mb-30">
        <div
          class="card h-100"
          style={{
            ...(active && {
              boxShadow: '0 15px 20px rgba(1,184,26,0.3)',
            }),
          }}>
          {/* {active && (
            <span class="badge badge-round badge-success badge-lg" style={{ position: 'absolute', top: '-10px', right: '10px' }}>
              Активный
            </span>
          )} */}

          <div class="card-body p-30">
            <div class="pricing d-flex align-items-center">
              <span class={`pricing__tag color-${active ? 'success' : color?.toLowerCase()} order-bg-opacity-${active ? 'success' : color?.toLowerCase()} rounded-pill `}>{name}</span>
            </div>
            <div class="pricing__price rounded">
              <p class="pricing_value display-3 color-dark d-flex align-items-center text-capitalize fw-600 mb-1">
                {currencyFormat(percentage(price, period == '3_months' ? 10 : period == '6_months' ? 20 : period == '12_months' ? 30 : 0))} <sup>₸</sup>
                <small class="pricing_user" style={{ textTransform: 'none' }}>
                  {label}
                  {/* {isYear ? 'в год' : 'в месяц'} */}
                </small>
              </p>
            </div>
            <div class="pricing__features">
              <ul>
                {details?.map((item) => (
                  <li style={{ textTransform: 'none' }}>
                    <span class="fa fa-check"></span>
                    {item?.value_based_permission ? (
                      <>
                        {' '}
                        {item?.value} {item?.display_text}
                      </>
                    ) : (
                      <>{item?.display_text}</>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div class="price_action align-items-start justify-content-start d-flex flex-column pb-30 ps-30">
            <button
              class={`btn btn-${active ? 'success' : color?.toLowerCase()} btn-default btn-squared text-capitalize px-30`}
              onClick={() => {
                navigate(`/cart?period=${period}&tariff=${id}`);
                // dispatch(tariffPayment({ tariff_id: id, payment_period: isYear ? 'year' : 'month', new_subscription: active ? 'false' : 'true' }));
              }}
              style={{ marginRight: 'auto' }}>
              {active ? 'Продлить' : 'Купить'}
            </button>{' '}
          </div>
        </div>
      </div>
    </>
  );
};

export default Tariff;
