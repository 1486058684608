import React, { useState } from 'react';
import styles from './ChatSidebar.module.scss';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
const ChatSidebar = ({ show, setShow }) => {
  const {
    createChat: { data: createChatData, loading: createChatLoading },
    getChatList: { data: chatListData },
    createdChatId,
  } = useSelector((state) => state.chat);

  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <>
      <div className={clsx(styles.wrap, show && styles.wrapShow)}>
        <div className={clsx(styles.header)}>
          {show ? (
            <div
              className={clsx(styles.menuBtnClose)}
              onClick={() => {
                setShow(false);
              }}></div>
          ) : (
            <div
              className={clsx(styles.menuBtn)}
              onClick={() => {
                setShow(true);
              }}></div>
          )}
        </div>
        <div
          className={clsx(styles.newChatWrap, createChatLoading && styles.newChatWrapDisabled)}
          onClick={() => {
            if (!createChatLoading) {
              navigate('/chat/new');
            }
          }}>
          {' '}
          <div className={clsx(styles.newChat)}></div>
          <div className={clsx(styles.newChatText, show && styles.newChatTextShow)}>Новый чат</div>
        </div>
        <div className={clsx(styles.history, show && styles.historyShow)}>
          {' '}
          <div className={clsx(styles.recentTitle)}>Недавнее</div>
          <div className={clsx(styles.list)}>
            {chatListData?.conversations?.length ? (
              [...chatListData?.conversations]
                ?.sort((a, b) => b.id - a.id)
                ?.map((chat) => (
                  <Link to={`/chat/${chat?.id}`} className={clsx(styles.item, (id == chat?.id || createdChatId == chat?.id) && styles.itemActive)}>
                    <div> {chat?.topic?.replaceAll(`"`, '')}</div>
                  </Link>
                ))
            ) : (
              <></>
            )}{' '}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatSidebar;
