import React from 'react';
import './ModalMassiveChanges.scss';
import Modal from '../../../components/site/common/Modal/Modal';
import Input from '../../../components/site/Input/Input';
import Button from '../../../components/site/Button/Button';
import { useForm } from 'react-hook-form';
const ModalMassiveChanges = ({ show, onClose }) => {
  const form = useForm();
  const setAutoReduction = form.watch('set-auto-reduction');
  const setAutoBoost = form.watch('set-auto-boost');
  return (
    <>
      <Modal width="580" show={show} onClose={onClose}>
        <div className="fw-500 color-dark fs-20 " style={{ lineHeight: '20px', marginBottom: '24px' }}>
          Массовое изменение
        </div>
        <div className="fw-500 color-dark fs-15" style={{ marginBottom: '24px' }}>
          Вы можете установить для всех товаров мин цены и макс цены. Достаточно указать процент
        </div>
        <div className="mb-20" style={{ margin: '0 0 0 10px' }}>
          <Input form={form} name="set-auto-reduction" type="checkbox" label="Установить автоснижение и минимальные цены для всех товаров" />
          {setAutoReduction && (
            <div style={{ marginLeft: '28px' }}>
              <Input form={form} name="value-auto-reduction" classInput={'form-control-lg mt-15'} styleWrap={{ maxWidth: '250px' }} placeholder="10%" />
              <Input form={form} name="have-min-auto-boost" type="checkbox" label="Применить только для товаров, которые уже имеют минимальную цену" classWrap="mb-15 mt-15" />{' '}
              <Input form={form} name="not-min-auto-boost" type="checkbox" label="Применить только для товаров, где уже достигнута минимальная цена" classWrap="mb-30" />
            </div>
          )}
        </div>
        <div className="" style={{ margin: '0 0 30px 10px' }}>
          {' '}
          <Input form={form} name="set-auto-boost" type="checkbox" label="Установить автоповышение и максимальное цены для всех товаров" />
          {setAutoBoost && (
            <div style={{ marginLeft: '28px' }}>
              <Input form={form} name="value-auto-reduction" classInput={'form-control-lg mt-15'} styleWrap={{ maxWidth: '250px' }} placeholder="10%" />
              <Input form={form} name="have-min-auto-boost" type="checkbox" label="Применить только для товаров, которые уже имеют максимальную цену" classWrap="mb-15 mt-15" />

              <Input form={form} name="not-min-auto-boost" type="checkbox" label="Применить только для товаров, где уже достигнута максимальная цена" classWrap="mb-15 mt-15 " />
              <Input form={form} name="have-min-auto-boost" type="checkbox" label="Установить автоповышение и максимальное цены для всех товаров" classWrap="mb-30" />
            </div>
          )}
        </div>

        <Button
          className="w-100"
          onClick={() => {
            onClose();
          }}>
          Применить
        </Button>
      </Modal>
    </>
  );
};

export default ModalMassiveChanges;
