import React from 'react';
import './Tab5.scss';
import StatCardList from '../../site/common/StatCardList/StatCardList';
import StatInlineList from '../../site/common/StatInlineList/StatInlineList';
import Table from '../../site/common/Table/Table';
import Button from '../../site/Button/Button';
const Tab5 = () => {
  const list1 = [
    {
      label: 'Остаток по Алмате',
      value: 'на 1 000 000 ₸',
      caption: '50 ед - 50 %',
      bigMargin: true,
    },
  ];
  const headers = ['Артикул продавца', 'Наименовнаие', 'Точка учета', 'Продано', 'На сумму', 'Закуплено', 'На сумму', 'Остаток', 'Остатка хватит'];

  const listTable = [{ row: ['10092334_89849348', 'Logitech черный', 'Алматы', '7 шт.', '62 293 ', '0 шт.', '0 ', '-7 шт.', '0 дн.'] }, { row: ['10092334_89849348', 'Logitech черный', 'Алматы', '7 шт.', '62 293 ', '0 шт.', '0 ', '-7 шт.', '0 дн.'] }];

  return (
    <>
      <StatInlineList list={list1} className="container-fluid" big />

      <Table className="mt-20" headers={headers} list={listTable} />
    </>
  );
};

export default Tab5;
