import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router';
import clsx from 'clsx';
import Input from '../Input/Input';
import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';
import Loading from '../Loading/Loading';
import { setPhoneNumber, setStepRegistration } from '../../../redux/slices/app.slice';
import { userSendCode } from '../../../redux/actions/user/userSendCode';
import { resetUserConfirmCode, resetUserResendCode, resetUserSendCode } from '../../../redux/slices/user.slice';
const StepInputPhone = () => {
  const form = useForm();
  const {
    userSendCode: { data: sendCodeData, loading: sendCodeLoading, error: sendCodeError },
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(userSendCode(data.phoneFormatted));
    dispatch(
      setPhoneNumber({
        value: data.phone,
        formatted: data.phoneFormatted,
      }),
    );
  };
  useEffect(() => {
    if (sendCodeData) {
      dispatch(setStepRegistration(1));
      dispatch(resetUserSendCode());
    }
  }, [sendCodeData]);

  console.log(form.formState.errors);
  useEffect(() => {
    form.register('phone', {
      validate: { value: (value, formValues) => value?.toString()?.length === 10 || 'Не полный формат телефона' },
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Регистрация</title>
      </Helmet>
      <div class="card border-0">
        <div class="card-header">
          <div class="edit-profile__title">
            <h4>Регистрация</h4>
          </div>
        </div>
        <div class="card-body">
          <div class="edit-profile__body">
            <div class="edit-profile__body">
              {' '}
              <div className="text-dark" style={{ fontSize: '16px', lineHeight: '23px', textAlign: 'center', whiteSpace: 'nowrap', marginBottom: '10px' }}>
                Введите ваш номер телефона
              </div>
              <div style={{ maxWidth: '195px', margin: '0 auto' }}>
                <Input
                  form={form}
                  name={'phone'}
                  type={'phone'}
                  //   label={'Введите ваш номер телефона'}
                  classWrap={!sendCodeError ? 'mb-25' : ''}
                  rules={{
                    required: { message: 'Обязательное поле', value: true },
                  }}
                />
                {sendCodeError && (
                  <div className="text-danger fs-13 " style={{ marginBottom: '15px', marginTop: '5px', textAlign: 'center' }}>
                    {sendCodeError?.message}
                  </div>
                )}
              </div>
              <div class="admin__button-group button-group d-flex pt-1 justify-content-md-start justify-content-center">
                <Button className=" lh-normal px-50 signIn-createBtn " style={{ margin: '0 auto' }} onClick={form.handleSubmit(onSubmit)}>
                  Отправить код
                </Button>
              </div>
              <p class="mt-20 d-flex align-items-center justify-content-center mb-0">
                Уже есть аккаунт?&nbsp;
                <Link
                  to="/login"
                  class="color-primary"
                  onClick={() => {
                    dispatch(setPhoneNumber(null));
                    dispatch(setStepRegistration(0));
                    dispatch(resetUserSendCode());
                    dispatch(resetUserResendCode());
                    dispatch(resetUserConfirmCode());
                  }}>
                  Войти
                </Link>
              </p>
              {sendCodeLoading && <Loading />}
              {/* {signUpError?.non_field_errors?.[0] && <p className="error pt-1 d-flex justify-content-center">{signUpError?.non_field_errors?.[0]}</p>} */}
            </div>
          </div>
        </div>

        {/* {signUpLoading && <Loading />} */}
      </div>
    </>
  );
};

export default StepInputPhone;
