import { createSlice } from '@reduxjs/toolkit';
import { initStateReferralGenerate, reducerReferralGenerate } from '../actions/referral/referralGenerate';
import { initStateReferralCheck, reducerReferralCheck } from '../actions/referral/referralCheck';

export const initialState = {
  ...initStateReferralGenerate,
  ...initStateReferralCheck,
};

export const referralSlice = createSlice({
  name: 'referral',
  initialState,
  reducers: {
    resetReferralGenerate(state, action) {
      state.referralGenerate = initStateReferralGenerate.referralGenerate;
    },
    resetReferralCheck(state, action) {
      state.referralCheck = initStateReferralCheck.referralCheck;
    },
  },
  extraReducers: {
    ...reducerReferralGenerate,
    ...reducerReferralCheck,
  },
});
export const { resetReferralGenerate, resetReferralCheck } = referralSlice.actions;
export const referralReducer = referralSlice.reducer;
