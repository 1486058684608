import React from 'react';
import './Tab2.scss';
import StatCardList from '../../site/common/StatCardList/StatCardList';
import StatInlineList from '../../site/common/StatInlineList/StatInlineList';
import Table from '../../site/common/Table/Table';
const Tab2 = () => {
  const list1 = [
    {
      label: 'Новых заказов',
      value: '0 ₸',
    },
    {
      label: 'На упаковке',
      value: '0',
    },

    {
      label: 'На доставке',
      value: '0',
    },
    {
      label: 'Выдано',
      value: '0',
    },
    {
      label: 'Отменено',
      value: '0',
    },
  ];

  const headers = ['Дата', 'Покупатель', 'Номер заказа', 'Артикул на каспи', 'Артикул продавца', 'Наименование', 'Кол-во', 'Цена', 'Сумма', 'Город доставки', 'Точка учета', 'Способ оплаты', 'Состояние заказа', 'Статус заказа'];

  const listTable = [
    { row: ['27.02.2024', 'Улукбек', '332451684', '9100196 на каспи', 'Артикул 9100196-57654645', 'Logitech серый', '1 шт.', '4 012 ₸', '4 012 ₸', 'Алматы', 'Алматы', 'Безналичная оплата', 'Архивный заказ', 'Выдан'] },
    { row: ['27.02.2024', 'Улукбек', '332451684', '9100196 на каспи', 'Артикул 9100196-57654645', 'Logitech серый', '1 шт.', '4 012 ₸', '4 012 ₸', 'Алматы', 'Алматы', 'Безналичная оплата', 'Архивный заказ', 'Выдан'] },
  ];
  return (
    <>
      <StatInlineList list={list1} className="container-fluid" />
      <Table className="mt-20" headers={headers} list={listTable} />
    </>
  );
};

export default Tab2;
