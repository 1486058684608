import React from 'react';
import styles from './Button.module.scss';
import clsx from 'clsx';
const Button = ({ className = '', color = 'primary', type = 'filled', children, ...props }) => {
  return (
    <>
      <button class={clsx('btn btn-default btn-squared ', type == 'filled' && `btn-${color}`, type == 'outline' && `btn-outline-${color}`, className)} {...props}>
        {children}
      </button>
    </>
  );
};

export default Button;
