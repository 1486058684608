import { createAsyncThunk, current } from '@reduxjs/toolkit';
import axios from 'axios';

export const initStateExportDamping = {
  exportDamping: { data: null, loading: false, error: null },
};

export const exportDamping = createAsyncThunk('user/exportDamping', async (data, { rejectWithValue, fulfillWithValue }) => {
  const token = localStorage?.getItem('token');
  if (!token) rejectWithValue({ error: 'PROBLEM_WITH_TOKEN' });
  return await axios
    .post(
      `${process.env.REACT_APP_SERVER_API}/kaspi-products/download/`,
      {},
      {
        responseType: 'arraybuffer',
        timeout: 180000,
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: '*/*',
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
      },
    )
    .then((res) => {
      return fulfillWithValue(res.data);
    })
    .catch((res) => {
      return rejectWithValue(res.response.data);
    });
});

export const reducerExportDamping = {
  [exportDamping.pending]: (state) => {
    state.exportDamping.loading = true;
  },
  [exportDamping.fulfilled]: (state, action) => {
    state.exportDamping.loading = false;
    state.exportDamping.data = action.payload;
    state.exportDamping.error = null;
  },
  [exportDamping.rejected]: (state, action) => {
    state.exportDamping.loading = false;
    state.exportDamping.error = action.payload;
  },
};
