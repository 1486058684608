import React, { useState } from 'react';
import './Tab4.scss';
import StatCardList from '../../site/common/StatCardList/StatCardList';
import StatInlineList from '../../site/common/StatInlineList/StatInlineList';
import Table from '../../site/common/Table/Table';
import Button from '../../site/Button/Button';
import Tab4AddEdit from '../Tab4AddEdit/Tab4AddEdit';
import AnimateHeight from 'react-animate-height';
const Tab4 = () => {
  const headers = ['Наименование', 'Кол - во', 'Остаток', 'Цена', 'НДС', 'Скидка', 'Сумма'];

  const listTable = [{ row: ['Logitech серый', '1 шт.', '90', '4 012 ₸', 'Без НДС', '0%', '50 шт.'] }, { row: ['Logitech серый', '1 шт.', '90', '4 012 ₸', 'Без НДС', '0%', '50 шт.'] }];
  const [height, setHeight] = useState(0);
  return (
    <>
      <AnimateHeight
        duration={400}
        height={height} // see props documentation below
      >
        {' '}
        <Tab4AddEdit title="Отгрузка №0124 от 27.02.2024" onClose={() => setHeight(0)} />
      </AnimateHeight>
      <AnimateHeight
        duration={200}
        height={!height ? 'auto' : 0} // see props documentation below
      >
        {' '}
        <div className="d-flex align-items-center">
          <Button className="me-10" onClick={() => setHeight(height === 0 ? 'auto' : 0)}>
            <i class="fas fa-plus " style={{ fontSize: '12px' }}></i>Списание
          </Button>
        </div>
      </AnimateHeight>

      <Table className="mt-20" headers={headers} list={listTable} />
    </>
  );
};

export default Tab4;
