import React, { useEffect } from 'react';
import styles from './TableOpportunity.scss';
import clsx from 'clsx';
import TableOpportunityRow from '../TableOpportunityRow/TableOpportunityRow';
import { useSelector } from 'react-redux';
import { useFieldArray, useForm } from 'react-hook-form';
const TableOpportunity = ({ form }) => {
  const headers = ['Город/место', 'Цена', 'Мин.цена', 'Макс.цена', 'Шаг', 'Остатки'];
  const {
    getDamping: { data: dampingData },
  } = useSelector((state) => state.damping);

  const { fields, append, prepend, remove, swap, move, replace } = useFieldArray({
    control: form.control,
    name: 'products',
  });
  useEffect(() => {
    let damping = [];
    if (dampingData) {
      damping = dampingData?.map((item) => {
        return {
          title: item?.title,
          image: `https://resources.cdn-kaspi.kz/img/m/p/${item?.images}?format=gallery-medium`,
          link: item?.link,
          productId: item?.product_id,
          merchantUid: item?.merchantUid,
          isSelectAll: false,

          cityList: [...item?.cityPrices]
            ?.sort(function (a, b) {
              return a?.city_name - b?.city_name;
            })
            ?.map((city, cityIndex) => {
              return {
                isSelected: false,
                name: city?.city_name,
                price: city?.value,
                step_price: item?.damping_info?.[cityIndex]?.step_price,
                max_price: item?.damping_info?.[cityIndex]?.max_price,
                min_price: item?.damping_info?.[cityIndex]?.min_price,
                step_price: item?.damping_info?.[cityIndex]?.step_price,
                damping_info_id: item?.damping_info?.[cityIndex]?.damping_info_id,
                quantity: city?.quantity,
              };
            }),
        };
      });
      form.reset();

      replace(damping);
    }
  }, [dampingData]);
  React.useEffect(() => {
    const subscription = form.watch((value, { name, type }) => {
      if (name?.includes('.isSelectAll') && type == 'change') {
        const productIndex = name.match(/\[(.*?)\]/)[1];
        const productRow = value.products[productIndex];
        if (productRow?.isSelectAll) {
          productRow?.cityList?.map((item, cityIndex) => {
            form.setValue(`products[${productIndex}].cityList[${cityIndex}].isSelected`, true);
          });
        } else {
          productRow?.cityList?.map((item, cityIndex) => {
            form.setValue(`products[${productIndex}].cityList[${cityIndex}].isSelected`, false);
          });
        }
      }
      if (name?.includes('].isSelected')) {
        var productIndex = name.match(/\[(.*?)\]/)[1];
        const productRow = value.products[productIndex];
        const isSelectedAllCity = productRow?.cityList?.filter((city) => city?.isSelected)?.length == productRow?.cityList?.length;
        if (isSelectedAllCity) {
          form.setValue(`products[${productIndex}].isSelectAll`, true);
        } else {
          form.setValue(`products[${productIndex}].isSelectAll`, false);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [form.watch]);

  return (
    <>
      <div className="userDatatable userDatatable--ticket userDatatable--ticket--2 mt-1">
        <div className="table-responsive" style={{ overflowX: 'scroll' }}>
          <table className="table mb-0 table-borderless" style={{ borderCollapse: 'separate', borderSpacing: '0 5px' }}>
            <thead>
              <tr className="userDatatable-header">
                {headers?.map((item, index) => (
                  <th style={{ ...(index !== 0 && { textAlign: 'center' }) }}>
                    <span className="userDatatable-title">{item}</span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {fields?.map((item, index) => (
                <TableOpportunityRow index={index} data={item} form={form} key={item?.productId} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TableOpportunity;
