import { createSlice } from '@reduxjs/toolkit';
import { initStateCreateChat, reducerCreateChat } from '../actions/chat/createChat';
import { initStateGetChatList, reducerGetChatList } from '../actions/chat/getChatList';
import { initStateGetChat, reducerGetChat } from '../actions/chat/getChat';
import { initStateSendMessageChat, reducerSendMessageChat } from '../actions/chat/sendMessageChat';

export const initialState = {
  ...initStateCreateChat,
  ...initStateGetChatList,
  ...initStateGetChat,
  ...initStateSendMessageChat,
  createdChatId: null,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    resetSendMessage(state, action) {
      state.sendMessageChat = initStateSendMessageChat.sendMessageChat;
    },
    resetCreateChat(state, action) {
      state.createChat = initStateCreateChat.createChat;
    },
    setCreatedChatId(state, action) {
      state.createdChatId = action.payload;
    },
  },
  extraReducers: {
    ...reducerCreateChat,
    ...reducerGetChatList,
    ...reducerGetChat,
    ...reducerSendMessageChat,
  },
});
export const { resetSendMessage, resetCreateChat, setCreatedChatId } = chatSlice.actions;
export const chatReducer = chatSlice.reducer;
