import React from 'react';
import './TariffCart.scss';
import { currencyFormat } from '../../../utils/currencyFormat';
import Loading from '../Loading/Loading';
import { useDispatch } from 'react-redux';
import { tariffPayment } from '../../../redux/actions/tariff/tariffPayment';
import Tooltip from '../common/Tooltip/Tooltip';
import clsx from 'clsx';
import { percentage } from '../../../pages/site/CartPage/CartPage';
const TariffCart = ({ period, setActive, annual_price, id, color = 'primary', price, name, details, active }) => {
  const dispatch = useDispatch();
  return (
    <>
      {' '}
      <div
        class=""
        onClick={() => {
          setActive({ id, name, price });
        }}>
        <div
          class="card h-100"
          style={{
            userSelect: 'none',
            ...(active && {
              border: '1px solid var(--color-primary)',
              boxShadow: '0 15px 20px rgba(var(--color-primary-rgba), 0.12)',
            }),
          }}>
          {/* {active && (
            <span class="badge badge-round badge-success badge-lg" style={{ position: 'absolute', top: '-10px', right: '10px' }}>
              Активный
            </span>
          )} */}

          <div class="card-body p-20">
            <div className="d-flex align-items-center justify-content-between">
              {' '}
              <h4 className={clsx(active && 'text-primary')}>{name}</h4>
              <div class="radio-theme-default custom-radio ">
                <input
                  class="radio"
                  type="radio"
                  // value={}
                  checked={active}
                  // id={id}
                />
                <label style={{ paddingLeft: '18px' }}></label>
              </div>
            </div>

            <div class="pricing__price rounded" style={{ display: 'flex', alignItems: 'end', flexWrap: 'wrap' }}>
              <p class="pricing_value display-4 color-dark d-flex align-items-center text-capitalize fw-600 mb-1">
                {currencyFormat(percentage(price, period == '3_months' ? 10 : period == '6_months' ? 20 : period == '12_months' ? 30 : 0))} <sup>₸</sup>
              </p>
              {period !== '1_month' && (
                <p class="pricing_value display-5 color-dark d-flex align-items-center text-capitalize fw-500 mb-1 " style={{ marginTop: '10px', marginLeft: '10px', color: 'var(--color-lighten)', textDecoration: 'line-through' }}>
                  {currencyFormat(price)} <sup style={{ fontSize: '12px' }}>₸</sup>
                </p>
              )}
            </div>
            <div class="pricing__features">
              <ul style={{ paddingTop: '15px' }}>
                {details?.map((item, index) => (
                  <li style={{ textTransform: 'none', marginBottom: '8px' }}>
                    <span class="fa fa-check"></span>
                    {item?.value_based_permission ? (
                      <>
                        {' '}
                        {item?.value} {item?.display_text}
                      </>
                    ) : (
                      <>{item?.display_text}</>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TariffCart;
