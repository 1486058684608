import React from 'react';
import './OpportunitySearchItem.scss';
import Button from '../../../components/site/Button/Button';
const OpportunitySearchItem = ({ name, image }) => {
  return (
    <>
      <div className="d-flex align-items-center flex-column">
        <div className="p-10" style={{ width: '100%', maxWidth: '200px', maxHeight: '160px', minWidth: '160px', minHeight: '160px' }}>
          <img className="img-fluid " style={{ objectFit: 'contain', width: '100%', height: '100%', display: 'block' }} src={image} />
        </div>
        <div className="fw-400 fs-15 color-dark text-center " style={{ marginBottom: '0px', lineHeight: '23px' }}>
          {name}
        </div>{' '}
        <div className="fw-400 fs-14 color-lighten text-center mb-10">123232323</div>
        <Button color="success" type={'outline'} style={{ height: '30px' }}>
          Выбрать
        </Button>
      </div>
    </>
  );
};

export default OpportunitySearchItem;
