import React, { useEffect, useState } from 'react';
import styles from './PricingPage.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { tariffGetList } from '../../../redux/actions/tariff/tariffGetList';
import Tariff from '../../../components/site/Tariff/Tariff';
import { Helmet } from 'react-helmet';
import Loading from '../../../components/site/Loading/Loading';
import moment from 'moment';
import { tariffPayment } from '../../../redux/actions/tariff/tariffPayment';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { resetTariffPayment } from '../../../redux/slices/tariff.slice';
import Tabs from '../../../components/site/common/Tabs/Tabs';
import { useMediaQuery } from '../../../utils/useMediaQuery';
const PricingPage = () => {
  const dispatch = useDispatch();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const {
    tariffGetList: { data: tariffList },
    tariffPayment: { data: tariffPaymentData, loading: tariffPaymentLoading, error: tariffPaymentError },
  } = useSelector((state) => state.tariff);
  const {
    userProfile: { data: userProfile },
  } = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(tariffGetList());
    return () => {
      dispatch(resetTariffPayment());
    };
  }, []);

  const [periodTariff, setPeriodTariff] = useState('1_month');

  const tabs = [
    {
      label: '1 месяц',
      value: '1_month',
      component: (
        <>
          <div class="row">
            {tariffList?.map((item, index) => (
              <Tariff period={periodTariff} {...item} color={item?.color ?? 'primary'} label={'в месяц'} price={item?.price * 1} active={userProfile?.tariff_info?.id == item?.id} />
            ))}
          </div>
        </>
      ),
    },
    {
      label: (
        <div style={{ display: 'flex' }}>
          3 месяца
          <div class="dm-badge-list d-flex align-items-center" style={{ transform: 'translateY(1px)', marginLeft: '0px' }}>
            <span class="badge badge-round badge-danger badge-lg" style={{ lineHeight: '21px' }}>
              -- 10%
            </span>
          </div>
        </div>
      ),
      value: '3_months',
      component: (
        <>
          <div class="row">
            {tariffList?.map((item, index) => (
              <Tariff period={periodTariff} {...item} color={item?.color ?? 'primary'} label={'за 3 месяца'} price={item?.price * 3} active={userProfile?.tariff_info?.id == item?.id} />
            ))}
          </div>
        </>
      ),
    },
    {
      label: (
        <div style={{ display: 'flex' }}>
          6 месяцев
          <div class="dm-badge-list d-flex align-items-center" style={{ transform: 'translateY(1px)', marginLeft: '0px' }}>
            <span class="badge badge-round badge-danger badge-lg" style={{ lineHeight: '21px' }}>
              -- 20%
            </span>
          </div>
        </div>
      ),
      value: '6_months',
      component: (
        <>
          <div class="row">
            {tariffList?.map((item, index) => (
              <Tariff period={periodTariff} {...item} color={item?.color ?? 'primary'} label={'за полгода'} price={item?.price * 6} active={userProfile?.tariff_info?.id == item?.id} />
            ))}
          </div>
        </>
      ),
    },
    {
      label: (
        <div style={{ display: 'flex' }}>
          12 месяцев
          <div class="dm-badge-list d-flex align-items-center" style={{ transform: 'translateY(1px)', marginLeft: '0px' }}>
            <span class="badge badge-round badge-danger badge-lg" style={{ lineHeight: '21px' }}>
              -- 30%
            </span>
          </div>
        </div>
      ),
      value: '12_months',
      component: (
        <>
          <div class="row">
            {tariffList?.map((item, index) => (
              <Tariff period={periodTariff} {...item} color={item?.color ?? 'primary'} label={'в год'} price={item?.price * 12} active={userProfile?.tariff_info?.id == item?.id} />
            ))}
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>Тарифы</title>
      </Helmet>
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 mb-30 mt-30">
            <div class="card banner-feature banner-feature--5" style={{ background: moment(userProfile?.end_date).isBefore(moment()) ? 'rgba(255,15,15,0.6)' : '#00a5e0', minHeight: '180px' }}>
              <div class="banner-feature__shape">
                <img src="/img/svg/group3320.svg" alt="img" />
              </div>
              <div class="d-flex justify-content-center">
                <div class="card-body" style={{ paddingBottom: '45px', paddingTop: '20px' }}>
                  <h1 class="banner-feature__heading color-white">Тарифы</h1>
                  {userProfile?.tariff_info ? (
                    <>
                      {' '}
                      <div className=" d-flex align-align-items-center text-white" style={{ marginTop: '5px', opacity: 0.9 }}>
                        <div style={{ marginTop: '3px', marginRight: '5px' }}>{moment(userProfile?.end_date).isBefore(moment()) ? 'Неактивный тариф' : ' Активный тариф'}</div>
                        <span style={{}} class={`pricing__tag color-${moment(userProfile?.end_date).isBefore(moment()) ? 'danger' : 'primary'} rounded-pill  ${moment(userProfile?.end_date).isBefore(moment()) ? 'bg-white' : 'bg-white'}`}>
                          {userProfile?.tariff_info?.name}
                        </span>
                      </div>
                      {moment(userProfile?.end_date).isBefore(moment()) ? (
                        <div class=" text-white d-flex align-items-center" style={{ marginTop: '10px', fontSize: '14px' }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="currentColor" style={{ marginRight: '4px' }}>
                            <path d="M15.09814,12.63379,13,11.42285V7a1,1,0,0,0-2,0v5a.99985.99985,0,0,0,.5.86621l2.59814,1.5a1.00016,1.00016,0,1,0,1-1.73242ZM12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Z"></path>
                          </svg>
                          Истек {moment(userProfile?.end_date).format('DD.MM.YYYY')}
                        </div>
                      ) : (
                        <div class=" text-white d-flex align-items-center" style={{ marginTop: '10px', fontSize: '14px' }}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="currentColor" style={{ marginRight: '4px' }}>
                            <path d="M15.09814,12.63379,13,11.42285V7a1,1,0,0,0-2,0v5a.99985.99985,0,0,0,.5.86621l2.59814,1.5a1.00016,1.00016,0,1,0,1-1.73242ZM12,2A10,10,0,1,0,22,12,10.01114,10.01114,0,0,0,12,2Zm0,18a8,8,0,1,1,8-8A8.00917,8.00917,0,0,1,12,20Z"></path>
                          </svg>
                          Истекает {moment(userProfile?.end_date).format('DD.MM.YYYY')}
                        </div>
                      )}
                      <div
                        onClick={() => {
                          dispatch(tariffPayment({ tariff_id: userProfile?.tariff_info?.id, payment_period: 'month', new_subscription: 'false' }));
                        }}
                        className="text-white extend-btn"
                        style={{ textDecoration: 'underline', textUnderlineOffset: '4px', marginTop: '10px', cursor: 'pointer', userSelect: 'none' }}>
                        Продлить
                      </div>
                    </>
                  ) : (
                    <h6 class="text-white " style={{ marginTop: '5px', opacity: 0.6, textTransform: 'none' }}>
                      <i class="uil uil-exclamation-circle"></i> Активный тариф не выбран
                    </h6>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>{' '}
        <Tabs style={{ width: isMobile ? '100%' : 'min-content', margin: '0 auto' }} isFilled list={tabs} activeTab={periodTariff} setActiveTab={setPeriodTariff} />
        {tariffPaymentLoading && <Loading />}
      </div>
    </>
  );
};

export default PricingPage;
