import React from 'react';
import './StatCardList.scss';
import StatCard from '../StatCard/StatCard';
const StatCardList = ({ list }) => {
  return (
    <>
      <div class="row">
        {list?.map((item) => (
          <StatCard {...item} />
        ))}
      </div>
    </>
  );
};

export default StatCardList;
