import React, { useEffect, useState } from 'react';
import styles from './ChatNewPage.module.scss';
import clsx from 'clsx';
import ChatInput from '../../../components/site/ChatInput/ChatInput';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { createChat } from '../../../redux/actions/chat/createChat';
import Chat from '../../../components/site/Chat/Chat';
import { resetCreateChat, setCreatedChatId } from '../../../redux/slices/chat.slice';
import { getChatList } from '../../../redux/actions/chat/getChatList';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
const ChatNewPage = () => {
  const data = [
    {
      name: 'Аналитика товара',
      icon: 'uil-chart',
    },
    {
      name: 'Популярные товары',
      icon: 'uil-box',
    },
    {
      name: 'Заменяет ассистента',
      icon: 'uil-smile',
    },
  ];
  const navigate = useNavigate();
  const form = useForm();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const [conversationId, setConversationId] = useState(null);
  const [chatList, setChatList] = useState([]);
  const [hideStart, setHideStart] = useState(false);
  const {
    userAuth: { data: userData, loading: userLoading, error: userError },
  } = useSelector((state) => state.user);
  const {
    createChat: { data: createChatData },
  } = useSelector((state) => state.chat);

  const onSubmit = (data) => {
    dispatch(createChat({ message: data.message }));
    setHideStart(true);
    setTimeout(() => {
      setChatList([
        {
          name: userData?.email?.substring(0, 1),
          self: true,
          text: data.message,
        },
      ]);
    }, 300);
  };
  useEffect(() => {
    if (createChatData) {
      if (!conversationId) {
        setConversationId(createChatData?.conversation_id);
        window.history.replaceState(null, 'Чат', `/chat/${createChatData?.conversation_id}`);
      }
      setChatList([
        ...chatList,
        {
          self: false,
          text: createChatData.response,
        },
      ]);

      dispatch(setCreatedChatId(createChatData?.conversation_id));
      dispatch(getChatList());
      dispatch(resetCreateChat());
    }
  }, [createChatData]);
  useEffect(() => {
    return () => {
      dispatch(setCreatedChatId(null));
    };
  }, []);

  return (
    <>
      {chatList?.length ? (
        <Chat loading={false} conversationId={conversationId} chatList={chatList} setChatList={setChatList} loadingMessage={!conversationId} />
      ) : (
        <div className={clsx(styles.wrap)}>
          <div className={clsx(styles.content, hideStart && styles.contentHide)}>
            <h1 className={clsx(styles.title)}>
              <span>Здравствуйте, {userData?.firstName ? userData?.firstName : userData?.email}!</span> <br />
              Что я умею
            </h1>
            <div className={clsx(styles.items)}>
              {data?.map((item) => (
                <div className={clsx(styles.item)}>
                  <div className={clsx(styles.itemName)}>{item?.name}</div>
                  <span className={clsx(`nav-icon uil ${item?.icon}`, styles.itemIcon)}></span>
                </div>
              ))}
            </div>
          </div>
          <ChatInput form={form} name={'message'} onClick={form.handleSubmit(onSubmit)} />
        </div>
      )}
    </>
  );
};

export default ChatNewPage;
