import React, { useId } from 'react';
import './Tooltip.scss';
import { Tooltip as Tool } from 'react-tooltip';
const Tooltip = ({ content }) => {
  const id = useId();
  return (
    <>
      <div data-tooltip-place="top-end" data-tooltip-delay-show={0.3} style={{ width: '16px', height: '16px', marginLeft: '7px' }} data-tooltip-id={id} data-tooltip-content={content}>
        <img style={{ display: 'block' }} src="/img/info-circle.svg" />
      </div>
      <Tool border="1px solid var(--border-light)" className="tooltip-custom" id={id} style={{ fontFamily: 'inherit', fontSize: '14px', padding: '8px 16px', backgroundColor: '#fff' }} />
    </>
  );
};

export default Tooltip;
