import { createSlice } from '@reduxjs/toolkit';
import { initStateTariffGetList, reducerTariffGetList } from '../actions/tariff/tariffGetList';
import { initStateTariffPayment, reducerTariffPayment } from '../actions/tariff/tariffPayment';

export const initialState = {
  ...initStateTariffGetList,
  ...initStateTariffPayment,
};

export const tariffSlice = createSlice({
  name: 'tariff',
  initialState,
  reducers: {
    resetTariffPayment(state, action) {
      state.tariffPayment = initStateTariffPayment.tariffPayment;
    },
  },
  extraReducers: {
    ...reducerTariffGetList,
    ...reducerTariffPayment,
  },
});
export const { resetTariffPayment } = tariffSlice.actions;
export const tariffReducer = tariffSlice.reducer;
